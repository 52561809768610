import React, {useContext, useEffect, useState} from 'react'
import {TabPanel, TabView} from "primereact/tabview";
import EventMap from "./eventMap";
import {Chart} from "primereact/chart";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faImage,
    faFilm,
    faChartLine,
    faCompass,
    faBracketsCurly,
    faFilms,
    faInfoCircle
} from '@fortawesome/pro-regular-svg-icons'
import {faCircle} from '@fortawesome/pro-solid-svg-icons'
import SnapshotTab from "./snapshotTab";
import VideoTab from "./videoTab";
import mainContext from "../../../../contexts/mainContext";
import MultiVideoTab from "./multiVideo";
import {faGrid2} from "@fortawesome/pro-light-svg-icons";
import {formatTimeOnly} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import eventTabsContext from "../../../../contexts/eventTabsContext";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import InfoTab from "./infoTab";
import {maxSpeed} from "../../../../../functions/formatting/maxspeed";
import {fetchBlockedEndpoints} from "../../../../../functions/fetchBlockedEndpoints";
import SpeedingChart from "./speedingChart";
import {Tooltip} from "primereact/tooltip";

const EventTabs = ({eventDeviceDetails, budget, videos, addVideo, videosUploaded,handleEventModalWidth,
                       handleVideosUploading, videosUploading, handleSpeed, handleSpeedLimit, speedLimit}) => {

    const {winWidth} = useContext(indexContext);
    const {event} = useContext(mainContext);

    let speedArray = [];
    let speedLimitArray = [];
    let directionArray = [];
    let impactArray = [];
    let gSensorXArray = [];

    let gSensorYArray = [];
    let gSensorZArray = [];

    let labelsArray = [];



    const [camPosArray, setCamPosArray] = useState([])
    const [frontChn, setFrontChn] = useState();
    const [driverChn, setDriverChn] = useState();
    const [activeIndex, setActiveIndex] = useState(0);


    let options1 = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: function(tooltipItem) {
                        return;
                    },
                    label: function(tooltipItem) {
                        let val = tooltipItem?.raw;
                        if (tooltipItem?.dataset?.label == 'G-Force (G)'){
                            val = val/100;
                        }
                        return tooltipTitle(tooltipItem?.dataset?.label, 'chart') + ' ' + val;
                    }
                },
                displayColors: false
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function(label){
                        return fetchUserLevel() === 'superuser' ?  `${formatTimeOnly(dateLabels[label])} (${data.labels[label]})` :
                            formatTimeOnly(dateLabels[label])
                    },
                },
            },
            y: {
                min:0
            }
        },
    }

    let options3 = {
        plugins: {
            tooltip: {
                callbacks: {
                    title: function(tooltipItem) {
                        return;
                    },
                    label: function(tooltipItem) {
                        let val = tooltipItem?.raw;
                        if (tooltipItem?.dataset?.label == 'G-Force (G)'){
                            val = val/100;
                        }
                        return tooltipTitle(tooltipItem?.dataset?.label, 'chart') + ' ' + val;
                    }
                },
                displayColors: false
            }
        },
        scales: {
            x: {
                ticks: {
                    callback: function(label){
                        return fetchUserLevel() === 'superuser' ?  `${formatTimeOnly(dateLabels[label])} (${data.labels[label]})` :
                            formatTimeOnly(dateLabels[label])
                    },
                },
            }
        },
    }


    const tooltipTitle = (label, pos) => {
        let str;
        switch (label){
            case 'gForce': str = 'G-Force (G)'; break;
            case 'Speed mph': str = 'Speed (mph)'; break;
            case 'Heading change': str = 'Heading change'; break;
            case 'x': str = 'X-axis'; break;
            case 'y': str = 'Y-axis'; break;
            case 'z': str = 'Z-axis'; break;
            default: str = label; break;
        }

        if (pos){
            str+= ':'
        }

        return str;
    }


    useEffect(async () => {
        if (event) {
            if (event?.videos?.length > 0){
                setActiveIndex(1);
            }

            let camPos = [];


            if (safeParse(event?.vehicleDetails)) {
                for (const cam of safeParse(event?.vehicleDetails)[0]?.cameras) {
                    if (cam?.camPosition === 'Front') {
                        setFrontChn(cam.channel)
                    }
                    if (cam?.camPosition === 'Driver') {
                        setDriverChn(cam.channel)
                    }
                    camPos[cam.channel] = cam?.camPosition
                }

                // const order = ["Front", "Rear", "Nearside", "Offside"]


                // const orderedArr = camPos.sort((a, b) => order.indexOf(a) - order.indexOf(b));


                setCamPosArray(camPos)
            }


        }
    }, [event])

    useEffect(() => {
        if (!safeParse(event?.vehicleDetails) && eventDeviceDetails){
            let camPos = []
            for (const cam of eventDeviceDetails?.cameras) {
                if (cam?.camPosition === 'Front') {
                    setFrontChn(cam.channel)
                }
                camPos[cam.channel] = cam?.camPosition
            }
            setCamPosArray(camPos)
        }
    }, [eventDeviceDetails])





    const eventTabHeader = (type) => {
        switch (type){
            case 'snapshot': return (
                <div>
                    <FontAwesomeIcon icon={faImage} />
                    &nbsp;AI Snapshot
                </div>
            )
            case 'multiVid': return (
                <div>
                    <FontAwesomeIcon icon={faGrid2} />
                    &nbsp;Multi Video
                </div>
            )
            case 'video': return (
                <div>

                    {(event?.videos?.length > 0 || videosUploaded?.length > 0) ?

                        <span>
                            {event?.videos.length === 1 ?
                            <span>
                                <FontAwesomeIcon icon={faFilm} />
                                &nbsp;Video&nbsp;
                            </span>
                            :
                                <span>
                                    <FontAwesomeIcon icon={faFilms} />
                                    &nbsp;Videos&nbsp;
                                </span>
                            }


                            {/*<FontAwesomeIcon icon={faCircle} color="red" />*/}
                        </span>
                    :
                        <div>
                            <FontAwesomeIcon icon={faFilm} />
                            &nbsp;Upload Video
                        </div>
                    }
                </div>
            )
            case 'graph': return (
                <div>
                    <FontAwesomeIcon icon={faChartLine} />
                    &nbsp;Metrics
                </div>
            )
            case 'map': return (
                <div>
                    <FontAwesomeIcon icon={faCompass} />
                    &nbsp;Location
                </div>
            )
            default: return (
                <div>
                    <FontAwesomeIcon icon={faBracketsCurly} />
                    &nbsp;Info
                </div>
            )
        }
    }




   const dateLabels = [];



    const data = {
        labels: [],


        //labels: ['February', 'March', 'April', 'May', 'June', 'July','bob'],
        datasets: [
            {
                label: tooltipTitle('Speed mph'),
                data: [],
                fill: false,
                borderColor: '#69de1f',
                tension: 0.3
            },
            {
                label: tooltipTitle('Speed limit (mph)'),
                data: [],
                fill: false,
                borderColor: 'red',
                tension: 0.3
            }
        ]
    };

    const data2 = {
        labels: [],
        //labels: ['February', 'March', 'April', 'May', 'June', 'July','bob'],
        datasets: [
            {
                label: tooltipTitle('gForce'),
                data: [],
                fill: false,
                borderColor: '#565656',
                tension: 0.3
            },
            {
                label: tooltipTitle('Heading change (°)'),
                data: [],
                fill: false,
                borderColor: '#6cc0d9',
                tension: 0.3
            }
        ]
    };


    const data3 = {
        labels: [],

        //labels: ['February', 'March', 'April', 'May', 'June', 'July','bob'],
        datasets: [
            {
                label: tooltipTitle('x'),
                data: [],
                fill: false,
                borderColor: '#69de1f',
                tension: 0.3
            },
            {
                label: tooltipTitle('y'),
                data: [],
                fill: false,
                borderColor: '#565656',
                tension: 0.3
            },
            {
                label: tooltipTitle('z'),
                data: [],
                fill: false,
                borderColor: '#6cc0d9',
                tension: 0.3
            }
        ]
    };


    const angleDistance = (a, b) => {
        const c = Math.abs(a - b) % 360;       // This is either the distance or 360 - distance
        return c > 180 ? 360 - c : c;
    }

    if (event !== null) {

        const labels = ['-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

        if (typeof safeParse(event.eventData) !== 'undefined') {
            safeParse(event.eventData).forEach((e, index) => {

                if (e.acquisitionTime !== '2048-00-00T00:00:00') {
                    speedArray.push((e.speed / 100 * 0.621371).toFixed(0));
                    speedLimitArray.push(maxSpeed(e.maxspeed))
                    if (index + 1 < event.eventData.length) {

                        directionArray.push(angleDistance(e.direction, event.eventData[(index + 1)].direction));
                    }
                    impactArray.push(e.gSensorImpact);

                    gSensorXArray.push(e.gSensorX);
                    gSensorYArray.push(e.gSensorY);
                    gSensorZArray.push(e.gSensorZ);
                    dateLabels.push(e.acquisitionTime);
                    labelsArray.push(labels[index])
                }
            });

            data.datasets[0].data = speedArray;
            data.datasets[1].data = speedLimitArray;
            data2.datasets[0].data = impactArray;
            data2.datasets[1].data = directionArray;
            data.labels = labelsArray;
            data2.labels = labelsArray;

            data3.datasets[0].data = gSensorXArray;
            data3.datasets[1].data = gSensorYArray;
            data3.datasets[2].data = gSensorZArray;
            data3.labels = labelsArray

        }

        const blocked = fetchBlockedEndpoints();
        let tabArr = ['map'];
        if (!blocked.includes('view-snapshot')) tabArr.push('snapshot');
        if (!blocked.includes('video-request') ||  (!blocked.includes('view-video') && event?.videos?.length > 0)) tabArr.push('video');
        if (winWidth > 800) tabArr.push('graph');
        if (winWidth > 800) tabArr.push('info');
        const sortingArr = ['snapshot', 'video', 'graph', 'map', 'info'];
        tabArr.sort((a, b) => sortingArr.indexOf(a) - sortingArr.indexOf(b));


            return (
                <div>
                    <TabView activeIndex={activeIndex} onTabChange={(e) => {
                        setActiveIndex(e.index)

                        if (e.index !== 1){
                            handleEventModalWidth(winWidth > 800 ? '45vw' : '100vw')
                        }
                    }}
                    scrollable>
                        {tabArr?.map((item) => (
                            <TabPanel header={eventTabHeader(item)} disabled={(item === 'graph' && !event?.eventData)}>
                                {item === 'snapshot' &&
                                    <div style={{overflowY: 'auto', overflowX:'hidden', fontSize: '12px', padding: '0 5px 10px 0'}}>
                                        <SnapshotTab event={event} camPosArray={camPosArray} frontChn={frontChn}
                                                     driverChn={driverChn}/>
                                    </div>
                                }
                                {item === 'video' &&
                                    <div style={{overflowY: 'auto', overflowX:'hidden', fontSize: '12px', padding: '0 5px 10px 0'}}>
                                        <VideoTab camPosArray={camPosArray} deviceDetails={eventDeviceDetails} budget={budget}
                                                  event={event} handleEventModalWidth={handleEventModalWidth}
                                                  frontChn={frontChn} addVideo={addVideo} videos={videos} speedLimit={speedLimit}
                                                  videosUploaded={videosUploaded} handleSpeed={handleSpeed} handleSpeedLimit={handleSpeedLimit}
                                                  handleVideosUploading={handleVideosUploading} videosUploading={videosUploading}/>
                                    </div>
                                }
                                {item === 'graph' &&
                                    <div style={{height: '45vh', overflowY: 'auto'}}>

                                        <SpeedingChart/>


                                        <h4 style={{marginLeft: 'none!important'}}>
                                            Vehicle Speed vs Speed Limit&nbsp;
                                            <FontAwesomeIcon icon={faInfoCircle}
                                                             className={"speedVsLimitChartTooltip"}/>
                                            <Tooltip target={".speedVsLimitChartTooltip"}>
                                                <div style={{width: '200px'}}>
                                                    The below chart shows the speed of the given vehicle along with the
                                                    speed limit for the given road travelled along for this event
                                                </div>
                                            </Tooltip>
                                        </h4>
                                        <Chart type="line" data={data} options={options1}/>

                                        <h4 style={{marginLeft: 'none!important'}}>
                                            Heading Change and G-Force&nbsp;
                                            <FontAwesomeIcon icon={faInfoCircle}
                                                             className={"headingAndGChartTooltip"}/>
                                            <Tooltip target={".headingAndGChartTooltip"}>
                                                <div style={{width: '200px'}}>
                                                    The below chart shows the heading change and the magnitude of the G-Force
                                                    associated with this heading change for this event
                                                </div>
                                            </Tooltip>
                                        </h4>

                                        <Chart type="line" data={data2} options={options1}/>

                                        {fetchUserLevel() === 'superuser' &&
                                            <Chart type="line" data={data3} options={options3}/>
                                        }
                                    </div>
                                }
                                {item === 'map' &&
                                    <div style={{display: 'flex', width: '100%', height: '45vh'}}>
                                        <EventMap event={event}/>
                                    </div>
                                }
                                {item === 'info' &&
                                    <div style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        height: '45vh',
                                        fontSize: '12px',
                                        userSelect: 'text'
                                    }}>
                                        <InfoTab/>
                                    </div>
                                }
                            </TabPanel>
                        ))}
                    </TabView>
                </div>
            )

    }
};

export default EventTabs

