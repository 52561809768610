import {API_URL} from '../../Constants.js'



const fetchStaticMapImage = (lat, lng, darkMap) => {

    const mapStyle = darkMap ? 'fleetfocus/cl74ogqly001b14n15u5x24zn'
        : 'fleetfocus/clfbkl32z000h01qln1hmq2b2';


    // let url = `https://api.mapbox.com/styles/v1/${mapStyle}/static/url-https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fen%2Fa%2Faa%2FBart_Simpson_200px.png(${lng},${lat})/${lng},${lat},`;
    // url +=`8/352x240?access_token=pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw`;

    let url = `https://api.mapbox.com/styles/v1/${mapStyle}/static/pin-s+000(${lng},${lat})/${lng},${lat},`;
        url +=`8/352x240?access_token=pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw`;

    return fetch(url)
        .then((response) => response.blob())
        .then((data) => {
                // console.log(data);

                return Promise.resolve(
                    data
                );


            }
        ).catch(err => {
            // console.log(err)
        })

}


export default fetchStaticMapImage;

// https://myfleetlive.org:8000/angFetchVidThumb/1000040_20210927112234-20210927112254_1_20

// https://myfleetlive.org:8000/view-snapshot/1000040_20211105084345_1_sm


// https://myfleetlive.org:8000/view-video/1000234_20211028110052-20211028110110_1_20_h265
