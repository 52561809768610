

import {NOTIFICATIONS_API_URL} from '../../Constants.js'
import clearStorage from "../../functions/localStorage/clearStorage";


const fetchNotifications = (data) => {

const token = localStorage.getItem('access_token')

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(data)
    };

    return fetch(NOTIFICATIONS_API_URL + '/ff-notifications', requestOptions)
        .then((response) => {

            if(response.status === 403) {
                clearStorage();
            }
            return response.json()
        })
        .then((data) => {
            return data
        });
}


export default fetchNotifications;
