import React from 'react';


const MovementReportSummary = ({journey}) => {

    console.log(journey)

    return(
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '20px', fontSize: '1.1em'}}>


            <div className="reportTotalBubble">
                <div><b>Total<br/>Distance:</b></div>
                <div style={{paddingBottom: '15px'}}>{journey?.distance} miles</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>Total<br/>Duration:</b></div>
                <div style={{paddingBottom: '15px'}}>{journey?.duration}</div>
            </div>

            <div className="reportTotalBubble">
                <div><b>Driver:</b></div>
                <div style={{paddingBottom: '15px'}}>{journey?.driver}</div>
            </div>
            <div className="reportTotalBubble">
                <div><b>End:</b></div>
                <div style={{paddingBottom: '15px'}}>{journey?.endAddress?.address}</div>
            </div>


        </div>

    )
}

export default MovementReportSummary
