import React, {useContext, useEffect, useRef, useState} from 'react';
import indexContext from "../../../contexts/indexContext";
import {Dialog} from "primereact/dialog";
import StackedSeverityClassificationIcon
    from "../../panelContent/grid/cardComponents/stackedSeverity&ClassificationIcon";
import {regFromDn} from "../../../../functions/formatting/regFromDn";
import {formatDateModalHeader} from "../../../../functions/formatting/formatDate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRefresh, faShareNodes, faTrash, faTrashSlash, faX} from "@fortawesome/pro-regular-svg-icons";
import Star from "../../panelContent/grid/cardComponents/star";
import EventModal from "./eventModal";
import LoadingSpinner from "../../loadingSpinner";
import mainContext from "../../../contexts/mainContext";
import gridContext from "../../../contexts/gridContext";
import {handleNotificationStaring} from "../../panelContent/grid/gridFunctions/handleNotificationStaring";
import {copyToClipboard} from "../../../../functions/copyToClipboard";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {Toast} from "primereact/toast";
import {TOAST_DURATION} from "../../../../Constants";
import {confirmPopup} from "primereact/confirmpopup";
import {handleNotificationReadV2} from "../../panelContent/grid/gridFunctions/handleNotificationRead";
import {findDataByKey} from "../../../../functions/db";

const EventModalParent = ({}) => {

    const {winWidth} = useContext(indexContext)
    const {event, refreshEvent, displayEventClickModal, closeModal, vehicles, viewed, toast
    } = useContext(mainContext);


    const [eventModalWidth, setEventModalWidth] = useState(winWidth >= 800 ? '40vw' : '100vw');
    const [starred, setStarred] = useState([]);
    const [vehicle, setVehicle] = useState()

    const handleNotificationStarred = async (notification) => {
        setStarred(await handleNotificationStaring(starred, notification, toast));
    }

    const handleEventModalWidth = (w) => {
        setEventModalWidth(w)
    }

    useEffect(() => {
        handleEventModalWidth(winWidth >= 800 ? '40vw' : '100vw')
        const curStarred = event?.starredBy?.includes(localStorage.getItem('email'));
        setStarred(curStarred ? [event._id] : [])
    }, [winWidth]);


    useEffect(async () => {
        if (event){
            const v = await findDataByKey('vehicles', event?.dn);
            setVehicle(v);
        }
    }, [event])




    return (
        <Dialog visible={displayEventClickModal} position={'right'} style={{width: eventModalWidth,
            height: winWidth >= 800 ? '95%' : '100%', maxHeight:'100%', }}
                modal={false} closable={false} resizable={false} className="eventModal"
        onHide={() => closeModal('eventClickedModal')}>


            {event?.startTime ?
                <React.Fragment>
                    <div style={{fontWeight: 'bold', fontSize: '16px',
                        display: 'flex', justifyContent: 'space-between', width: '100%', paddingTop: '1.5rem', borderTopRightRadius: '6px',
                        borderTopLeftRadius: '6px'}}>


                        {/*<span style={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleEvent(driverBehaviour?._id)}>*/}
                        {/*    <FontAwesomeIcon icon={faRefresh} />*/}
                        {/*</span>*/}
                        <div style={{display: 'flex', gap: '10px',flex:1}}>
                            <StackedSeverityClassificationIcon size="35px" event={event} />


                            <div onClick={() => copyToClipboard(event?._id)}>
                                Driving Event - {vehicle?.properties?.registration}

                                <div style={{fontWeight: 'normal'}}>
                                    {formatDateModalHeader(event?.startTime)}
                                </div>
                            </div>
                        </div>

                        <div style={{paddingTop: '5px', paddingRight: '5px', fontSize: '16px', color: 'var(--text-color)', textAlign: 'right'}}>


                            <FontAwesomeIcon icon={faShareNodes}  style={{marginRight: '15px', cursor: 'pointer'}}
                                             onClick={() => {
                                                 toast?.current?.replace({severity:'success', summary: 'Copied!', detail: 'Event link copied to clipboard',
                                                     life: TOAST_DURATION});
                                                 copyToClipboard(`https://${window.location.host}/events,${event?.dn},${event?._id}`)
                                             }}/>





                            <FontAwesomeIcon icon={faRefresh}  style={{marginRight: '15px', cursor: 'pointer'}} onClick={() => refreshEvent(event)}/>



                            {/*<span onClick={async () => {*/}
                            {/*    await handleNotificationStarred(event,(starred?.includes(event._id) ? 'remove' : 'add'));*/}
                            {/*}} style={{marginRight: '15px'}}>*/}
                            {/*           <Star data={event} starred={starred}/>*/}
                            {/*        </span>*/}


                            {/*{(viewed?.includes(event._id) || event?.readBy?.includes(localStorage.getItem('email'))) ?*/}

                            {/*    <FontAwesomeIcon icon={faTrashSlash}  style={{marginRight: '15px', cursor: 'pointer'}}*/}
                            {/*                     onClick={async (e) => {*/}
                            {/*                         await handleNotificationRead(e, event,'read');*/}
                            {/*                     }}/>*/}
                            {/*    :*/}
                            {/*    <FontAwesomeIcon icon={faTrash} style={{marginRight: '15px', cursor: 'pointer'}}*/}
                            {/*                     onClick={async (e) => {*/}
                            {/*                         await handleNotificationRead(e, event, 'unread');*/}
                            {/*                     }}/>*/}

                            {/*}*/}


                            {/*<FontAwesomeIcon icon={faTrash} style={{marginRight: '15px'}}/>*/}
                            <FontAwesomeIcon icon={faX} style={{cursor: 'pointer'}} onClick={() => closeModal('displayEventClickModal')} />
                        </div>
                    </div>

                    <div style={{marginTop: '30px'}}>
                        <EventModal handleEventModalWidth={handleEventModalWidth}/>
                    </div>
                </React.Fragment>
                :

                <div style={{position: 'absolute', top: '50%', left: '50%', transform:
                        'translate(-50%, -50%)'}}>
                    <LoadingSpinner />
                </div>
                // <ProgressSpinner style={{position:'relative', top: '50%',left:'50%', transform: 'translate(-50%, -50%)'}}/>
            }
        </Dialog>

    )
}
export default EventModalParent
