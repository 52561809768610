import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "mapbox-gl/dist/mapbox-gl.css";
import {purple} from '../../../panelContent/map/CustomIcons';
import MapCustomControl2 from '../../../panelContent/map/MapCustomControl2.js';
import {Colours} from "../../../../../colours";
import {safeParse} from "../../../../../functions/formatting/safeParse";
import indexContext from "../../../../contexts/indexContext";
import ReactDOM from "react-dom";
import fetchReverseGeocode from "../../../../../api/fetchReverseGeocode";
import {applySpacing} from "../../../../../functions/formatting/applySpacing";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";




const styles = {
    flex: "1",
    position: "relative",
};


const generatePlaceMarker = (placeSelected) => {
    return {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Point',
                    coordinates: placeSelected?.location?.coordinates
                }
            }
        ]
    }
}


const createGeoJSONCircle = (center, radius, points) => {
    if(!points) points = 64;
    var coords = {
        latitude: center[1],
        longitude: center[0]
    };

    var km = radius/1000;
    var ret = [];
    var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
    var distanceY = km/110.574;

    var theta, x, y;
    for(var i=0; i<points; i++) {
        theta = (i/points)*(2*Math.PI);
        x = distanceX*Math.cos(theta);
        y = distanceY*Math.sin(theta);

        ret.push([coords.longitude+x, coords.latitude+y]);
    }
    ret.push(ret[0]);

    return {
        "type": "geojson",
        "data": {
            "type": "FeatureCollection",
            "features": [{
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [ret]
                }
            }]
        }
    };
};


const PlaceModalMap = ({placeSelected, handleNewCoords, handleLocation, radius}) => {

    const {darkMap} = useContext(indexContext)



    const [map, setMap] = useState(null);
    const mapContainer = useRef(null);


    const CustomControl = new MapCustomControl2();

    const elStopped = document.createElement('div');
    const markerStopped = new mapboxgl.Marker(elStopped, {
        offset: [0, -50]
    });







    const forEachLayer = (text, cb) => {
        map.getStyle().layers.forEach((layer) => {
            if (!layer.id.includes(text)) return;
            cb(layer);
        });
    }


    const changeStyle = (style) => {
        if (map){
            const savedLayers = [];
            const savedSources = {};
            const layerGroups = [
                'place-point-layer'
            ];

            layerGroups.forEach((layerGroup) => {
                forEachLayer(layerGroup, (layer) => {
                    savedSources[layer.source] = map.getSource(layer.source).serialize();
                    savedLayers.push(layer);
                });
            });

            map.setStyle(`mapbox://styles/${style}`);

            setTimeout(() => {

                purple.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.onload = () => {
                        if (!map.hasImage(icon.name)){
                            map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                        }
                    }
                    customIcon.src = icon.src;
                });

                Object.entries(savedSources).forEach(([id, source]) => {
                    try {
                        map.addSource(id, source);
                    } catch (e){
                        // console.log(e)
                        // console.log(source)
                    }
                });

                savedLayers.forEach((layer) => {

                    const mapLayer = map.getLayer(layer.id);

                    if(typeof mapLayer === 'undefined') {
                        map.addLayer(layer);
                    }
                });
            }, 1500);
        }
    }


    useEffect(() => {
        if(map){
            if (darkMap){
                changeStyle('fleetfocus/cl74ogqly001b14n15u5x24zn/draft')
            } else {
                changeStyle('fleetfocus/clfbkl32z000h01qln1hmq2b2/draft')
            }
        }
    }, [darkMap])



    useEffect(() => {


        mapboxgl.accessToken =  "pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw";

        // const mapStyle = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';
        // const mapStyle = 'mapbox://styles/fleetfocus/ckzd4tbpk005e14qe1hs9tqmd?optimize=true';
        const mapStyle = darkMap ? 'mapbox://styles/fleetfocus/cl74ogqly001b14n15u5x24zn/draft'
            : 'mapbox://styles/fleetfocus/clfbkl32z000h01qln1hmq2b2/draft';


        // const markerRef = useRef(new mapboxgl.Marker(el));



        const zoom = placeSelected ? 15 : 5 ;
        const center = placeSelected?.location?.coordinates ? placeSelected?.location?.coordinates :
            [0.667367, 51.765907]

        const initializeMap = ({setMap, mapContainer}) => {

            const map = new mapboxgl.Map({
                container: mapContainer.current,
                // style: 'mapbox://styles/mapbox/streets-v11',
                style: mapStyle,
                center:center,
                zoom: zoom
            });


            map.addControl(CustomControl, 'top-right');


            map.on('load', () => {

                purple.forEach(icon => {
                    let customIcon = new Image(icon.width, icon.height);
                    customIcon.onload = () => {
                        if (!map.hasImage(icon.name)){
                            map.addImage(icon.name, customIcon, {sdf: icon.sdf})
                        }
                    }
                    customIcon.src = icon.src;
                });


                map.addControl(
                    new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        countries: 'gb',
                        placeholder: 'address search',
                        mapboxgl,

                    }),  'bottom-right'
                );


                map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);

                setMap(map);
                map.resize();

                map.scrollZoom.setWheelZoomRate(2);

                map.dragRotate.disable();
                map.touchZoomRotate.disableRotation();

                if (placeSelected){
                    const data2 = createGeoJSONCircle(placeSelected?.location?.coordinates, radius);
                    const data =  generatePlaceMarker(placeSelected)
                    map.addSource('place-data-point', {type: 'geojson', data: data});
                    map.addSource("polygonData", data2);

                } else {
                    map.addSource('place-data-point', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: {
                                        type: 'Point',
                                        coordinates: []
                                    }
                                }
                            ]
                        }
                    });

                    map.addSource('polygonData', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: [
                                {
                                    type: 'Feature',
                                    properties: {},
                                    geometry: {
                                        type: 'Polygon',
                                        coordinates: []
                                    }
                                }
                            ]
                        }
                    });
                }




                map.addLayer({
                    "id": "polygon",
                    "type": "fill",
                    "source": "polygonData",
                    "layout": {},
                    "paint": {
                        "fill-color": getComputedStyle(document.body).getPropertyValue('--journey-line-colour'),
                        "fill-opacity": 0.6
                    }
                });


                map.addLayer({
                    id: 'place',
                    source: 'place-data-point',
                    type: 'symbol',
                    layout: {
                        'icon-image': 'mapMarker',
                        'icon-ignore-placement': false,
                        'icon-allow-overlap': true,
                        'text-allow-overlap': true,
                        'icon-size': {
                            stops: [
                                [1, 0.6],
                                [14, 0.7],
                                [18, 0.9]
                            ]
                        },
                        'icon-anchor': 'center',
                    },
                    paint: {
                        'icon-color': darkMap ? '#CE93D8' : '#000000',
                        'text-color': '#ffffff'
                    }
                });




                map.flyTo({
                        center: center,
                        zoom: zoom,
                        speed: 2
                    });

                map.on('click', async (e) => {
                    const coordinates = e.lngLat;
                    ReactDOM.render(<MapPopup lat={coordinates.lat} lng={coordinates.lng} map={map}/>, elStopped)
                    markerStopped.setLngLat(coordinates).addTo(map);
                });
            });



        };


        if (!map) initializeMap({setMap, mapContainer});



    }, [map]);


    useEffect(() => {
        if (radius && map){
            if (map){
                const data2 = createGeoJSONCircle(placeSelected?.location?.coordinates, radius);
                map.getSource("polygonData").setData(data2.data);
            }
        }
    }, [radius])



    const moveMarker = (e, lat, lng, map, location) => {
        e.stopPropagation();
        handleLocation(location)

        const data = createGeoJSONCircle([lng, lat], radius);
       map.getSource("polygonData")?.setData(data.data);

        map.getSource("place-data-point").setData({
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat]
                    }
                }
            ]
        });

        markerStopped.remove();
        handleNewCoords([lng, lat])
    }




    const MapPopup = ({lat, lng, map}) => {

        const [location, setLocation] = useState();

        useEffect(async() => {

            const loc = await fetchReverseGeocode(lat, lng);
            setLocation(loc?.features?.[0]?.properties?.address)
        }, [lat, lng])




        return (
            <div style={{color:'var(--text-color)', background: 'var(--surface-card)', textAlign: 'center', borderRadius: '2px', padding: '5px'}}>
                {/*You have clicked here - {lat}, {lng}*/}
                {/*<br />*/}
                {applySpacing(location?.house_number)}
                {applySpacing(location?.building)}
                {applySpacing(location?.road)}
                {location?.village && <React.Fragment>{applySpacing(location?.village)}</React.Fragment>}
                {location?.town && <React.Fragment>{applySpacing(location?.town)}</React.Fragment>}
                {location?.city && <React.Fragment>{applySpacing(location?.city)}</React.Fragment>}
                {location?.hamlet && <React.Fragment>{applySpacing(location?.hamlet)}</React.Fragment>}


                {location?.postcode}

                <br />
                <button className="p-button p-button-sm" onClick={(e) => moveMarker(e, lat, lng, map, location)}>
                    Move place here
                </button>
            </div>
        )
    }


    return (

        <div ref={el => (mapContainer.current = el)} style={styles}/>

    );

}
export default PlaceModalMap

