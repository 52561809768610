import React, {useContext, useEffect, useState} from 'react';
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";
import {Tooltip} from "primereact/tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faTrashSlash} from "@fortawesome/pro-light-svg-icons";
import SelectedTick from "../../../../../svgs/eventClassificationIcons/selectedTick";
import DeviceMissingStacked
    from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/deviceMissing";
import HealthStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/pin-health";
import HardDiskStacked from "../../../../../svgs/eventClassificationIcons/stackedClassificationIcons/hardDisk";
import {formatDateNotificationsTable} from "../../../../../functions/formatting/formatDate";
import {friendlyFleetName} from "../../../../../functions/formatting/friendlyFleetName";
import {Dropdown} from "primereact/dropdown";
import EventGridItemSkeleton from "../../../panelContent/grid/events/eventGridItemSkeleton";
import indexContext from "../../../../contexts/indexContext";
import mainContext from "../../../../contexts/mainContext";
import gridContext from "../../../../contexts/gridContext";
import {findDataByKey} from "../../../../../functions/db";
import fetchStaticMapImage from "../../../../../api/media/fetchStaticMapImage";
import noImg from "../../../../../svgs/noImageThumb.png";
import fetchSnapshot from "../../../../../api/media/fetchSnapshot";
import hardDiskHealth from "../../../../../svgs/storageUnavailable.png";
import cameraUnavailable from "../../../../../svgs/cameraUnavailable.png";
import postMarkNotificationRead from "../../../../../api/notifications/postMarkNotificationRead";
import postMarkNotificationTypeRead from "../../../../../api/notifications/postMarkNotificationRead";


const HealthCard = ({notification, rawNotifications, refreshNotifications}) => {

    const {fleets, handleRefreshNotifications} = useContext(indexContext)
    const {fleetsToShow, handleFleetsToShow, handleVehicle, vehicles, handleEvent, pageToShow} = useContext(mainContext);



    const [img, setImg] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [options, setOptions] = useState();
    const [hover, setHover] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [vehicle, setVehicle] = useState()



    useEffect(async () => {
        if (mounted){

            const v = await findDataByKey('vehicles', notification?.dn)
            setVehicle(v);



            if (notification?.notificationType.includes('missing')){
                if (notification?.notificationDetails?.lastLocation){
                    fetchStaticMapImage(notification?.notificationDetails?.lastLocation?.lat, notification?.notificationDetails?.lastLocation?.lng).then(r => {
                        setImg(URL.createObjectURL(r));
                    });
                } else {
                    setImg(noImg);
                }
            } else if (notification?.notificationType.includes('cameraHealth') && !notification?.notificationType.includes('unavailableCamera')){
                const optionsArr = [];
                if (notification?.notificationDetails?.events?.length > 0){
                    notification?.notificationDetails?.events?.forEach((item, index) => {
                        optionsArr.push({
                            i: index,
                            value: item
                        });
                    });
                }

                setOptions(optionsArr);

                const r = await fetchSnapshot(notification.notificationDetails?.snapshot, 'sm');
                setImg(URL.createObjectURL(r));
            } else if (notification?.notificationType.includes('hardDiskHealth')){
                setImg(hardDiskHealth);
            } else {
                setImg(cameraUnavailable)

            }
            await new Promise(r => setTimeout(r, 500));
            setIsLoaded(true);
        }
    }, [mounted, notification]);

    useEffect(async () => {
        setMounted(true)

        return () => {
            setMounted(false)
            setImg(null)
        }
    }, [])



    const markRead = async () => {
        const theSet = new Set(notification?.notificationType);
        theSet.delete('cameraHealth');
        theSet.delete('health')
        theSet.delete('unavailableCamera')

        const dataToSend = {
            type: Array.from(theSet).join(),
            user: localStorage.getItem('email'),
            dn: notification.dn
        }

        await postMarkNotificationTypeRead(dataToSend);
        await refreshNotifications();
        handleRefreshNotifications();
    }


    return (
        <React.Fragment key={notification?._id}>
            {isLoaded ?
                <div style={{
                    width: '100%',
                    height: '100%',
                    border: 'solid 1px var(--surface-border)',
                    borderRadius: '5px',
                    padding: '10px 0px 10px 0px',
                    marginBottom: '10px'
                }} className="surface-card">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '5px',
                        padding: '0px 10px 0px 10px'
                    }}>
                        <div style={{flex: 1, fontSze: '15px', fontWeight: 'bold'}}>
                            {notification?.notificationDetails?.title}

                            {fetchUserLevel() === 'superuser' && notification.notificationType.includes('cameraHealth') &&
                                !notification.notificationType.includes('unavailableCamera') &&

                                <span style={{marginLeft: '5px'}}>({notification?.notificationDetails?.events?.length}/10)</span>

                            }

                            <br/>
                            <div style={{fontSize: '12px', fontWeight: 'normal', lineHeight: '15px'}}>
                                {notification.notificationType.includes('missing') && `Last known position:`}
                                {notification.notificationType.includes('cameraHealth') && `Last snapshot:`}
                            </div>
                        </div>

                        <Tooltip target={".markReadButton" + notification._id}
                                 id={'delete' + notification._id}>Delete</Tooltip>

                        <div style={{
                            paddingLeft: '4px',
                            lineHeight: '29px',
                            width: '25px',
                            height: '25px',
                            borderRadius: '50%',
                            background: 'var(--surface-border)'
                        }}>

                            <span className={"markReadButton" + notification._id} data-pr-position="bottom"
                                  onClick={async (e) => {
                                      e.stopPropagation();
                                      await markRead()
                                  }}>

                               <FontAwesomeIcon icon={faTrash} style={{
                                   cursor: 'pointer',
                                   fontSize: "18px",
                                   marginRight: '5px'
                               }}/>

                            </span>
                        </div>


                        {notification?.notificationType.includes('missing') &&
                            <DeviceMissingStacked event={notification} height="25px"/>
                        }
                        {notification?.notificationType.includes('cameraHealth') &&
                            <HealthStacked event={notification} height="25px"/>
                        }
                        {notification?.notificationType.includes('hardDiskHealth') &&
                            <HardDiskStacked event={notification} height="25px"/>
                        }


                    </div>

                    <div style={{textAlign: 'center', margin: '0 auto', marginTop: '15px', position: 'relative'}}>

                        <img src={img} style={{width: '100%', borderRadius: '3px', aspectRatio: '16/9'}}/>
                    </div>

                    <div style={{
                        textAlign: 'left',
                        position: 'relative',
                        zIndex: '10',
                        margin: '0 auto',
                        borderRadius: '2px',
                        marginTop: '2px',
                        padding: '10px 10px 0px 10px'
                    }}>

                        {notification?.notificationType.includes('missing')?

                            <React.Fragment>
                                Last connected:&nbsp;
                                {formatDateNotificationsTable(notification?.notificationDetails?.lastLocation?.at)}
                            </React.Fragment>

                        :
                            <React.Fragment>
                                Issue detected:&nbsp;
                                {formatDateNotificationsTable(notification.notificationCreatedTimestamp)}
                            </React.Fragment>
                        }


                    </div>


                    <div style={{
                        textAlign: 'left',
                        position: 'relative',
                        zIndex: '10',
                        margin: '0 auto',
                        borderRadius: '2px',
                        marginTop: '2px',
                        padding: '0px 10px 0px 10px'
                    }}
                    >
                        {friendlyFleetName(notification?.fleetId, fleets)}
                    </div>

                    {notification?.notificationType?.includes('cameraHealth') && fetchUserLevel() === 'superuser' &&
                        !notification?.notificationType?.includes('unavailableCamera') &&
                        <div style={{
                            textAlign: 'left',
                            position:'relative',
                            zIndex: '10',  margin: '0 auto', borderRadius: '2px', marginTop: '2px', padding: '0px 10px 10px 10px'}}>

                            <Dropdown onChange={(e) => handleEvent({_id: e.value, dn: notification.dn})}
                                      options={options} editable placeholder="Select Event"  optionLabel="i"/>

                        </div>
                    }


                </div>
                :
                <EventGridItemSkeleton />
            }

        </React.Fragment>
    )
}

export default HealthCard