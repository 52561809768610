import React, {useContext, useEffect, useRef, useState} from 'react';
import {Chip} from "primereact/chip";
import mainContext from "../../../../contexts/mainContext";
import {getStoreData} from "../../../../../functions/db";
import {Dropdown} from "primereact/dropdown";
import postEventTagEditor from "../../../../../api/events/postEventTagEditor";
import {TOAST_DURATION} from "../../../../../Constants";

const EventTagChips = ({}) => {

    const {event, toast} = useContext(mainContext);

    const [tags, setTags] = useState(event?.eventTags)
    const [tagLib, setTagLib] = useState();
    const [editable, setEditable] = useState(false);
    const [options, setOptions] = useState([]);


    const tagsToRemove = useRef([]);
    const tagsToAdd = useRef([]);


    const removeTag = val => {

        const clone = JSON.parse(JSON.stringify(tags))
        const index = clone.indexOf(val);

        if (index !== -1) {
            clone.splice(index, 1);
            tagsToRemove.current.push(val)
        }


        let toAdd = tagsToAdd.current.indexOf(val)
        if (toAdd !== -1){
            tagsToAdd.current.splice(toAdd, 1);
        }


    }


    const addTag = val => {
        const clone = JSON.parse(JSON.stringify(tags))
        clone.push(val)
        let toRemove = tagsToRemove.current.indexOf(val)
        if (toRemove !== -1) tagsToRemove.current.splice(toRemove, 1);
        setTags(clone);
        if (!event?.eventTags.includes(val)) tagsToAdd.current.push(val)
    }

    const saveTagChanges = async () => {

        if (tagsToAdd.current?.length > 0 || tagsToRemove.current?.length > 0){
            const data = {
                dn: event?.dn,
                eventId: event?._id,
                tagsToAdd: tagsToAdd.current,
                tagsToRemove:tagsToRemove.current
            }
            tagsToRemove.current = [];
            tagsToAdd.current = [];

            const result = await postEventTagEditor(data);

            let summary = result?.modifiedCount === 1 ? 'Tags updated' : 'Tags not updated'
            let message =  result?.modifiedCount === 1 ? 'Tags have been updated, event score will be recalculated' :
                'No changes have been made'

            toast.current.show({ severity: 'success', summary: summary, detail: message,
                life: TOAST_DURATION });


        }


    }


    useEffect(async () => {

        let arr = [];

        if (!tagLib){
            const libRaw = await getStoreData('eventTags');

            let lib  ={};

            for (let i = 0; i < libRaw?.length; i++) {
                lib[libRaw[i].tag] = libRaw[i];
                arr.push(libRaw[i])
            }
            setOptions(arr);
            setTagLib(lib);
        }
    }, [])


    return (
        <React.Fragment>
            {tagLib && tags?.map(tag => (
                <Chip label={tagLib?.[tag]?.displayName ? tagLib?.[tag]?.displayName : tag}
                      className="activeChips" style={{fontStyle: tagLib?.[tag]?.displayName ? 'normal' : 'italic' }}
                      removable={editable && tag !== 'video' && tag !== 'snapshot'} onRemove={() => removeTag(tag)}/>
            ))}


            {editable && tagLib ?

                <React.Fragment>
                    <Dropdown options={options} optionLabel={'displayName'} placeholder={'Add tag'}
                    onChange={e =>addTag(e.value.tag)} filter />

                    <Chip label={'save'} onClick={() => {
                        saveTagChanges()
                        setEditable(false)
                    }}/>

                </React.Fragment>
            :
                <div>
                    {/*<Chip label={'edit'} onClick={() => setEditable(true)}/>*/}
                </div>

            }

        </React.Fragment>
    )
}
export default EventTagChips