import React, {useContext, useEffect, useState} from 'react';
import NotificationGridMedia from "./notificationsGridMedia";
import NotificationGridUpload from "./notificationGridUpload";
import mainContext from "../../../../contexts/mainContext";
import NotificationGridModeChanged from "./notificationGridModeChanged";
import NotificationGridHealth from "./NotificationGridHealth";
import indexContext from "../../../../contexts/indexContext";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import {fetchUserLevel} from "../../../../../functions/fetchUserLevel";

const NotificationGridItem = ({notification}) => {


    const {openModal, handleDeviceDetails, vehicle, handleEvent} = useContext(mainContext)

    const [mediaBoxStyle, setMediaBoxStyle] = useState({
        padding: '10px 0px',
        borderRadius: 'inherit',
        width: '100%',
        height: '100%',
        cursor: 'pointer'

    })

    const handleClick =  async (notification) => {
        if (!vehicle || vehicle?.properties?.dn !== notification.dn){
            const details = await fetchDeviceDetails({properties: {dn: notification.dn}});
            handleDeviceDetails(details[0])
            openModal('displayDeviceDetailsModal')
        } else {
            openModal('displayDeviceDetailsModal')
        }
    }

    //
    // useEffect(() => {
    //     if(notification && selected){
    //
    //         if (selected.has(notification?._id)){
    //             setMediaBoxStyle({
    //                 padding: '10px 0px 5px',
    //                 borderRadius: 'inherit',
    //                 width: '100%',
    //                 height: '100%',
    //                 background: 'var(--card-selected)',
    //                 cursor: 'pointer'
    //             })
    //         } else {
    //             setMediaBoxStyle({
    //                 padding: '10px 0px 5px',
    //                 borderRadius: 'inherit',
    //                 width: '100%',
    //                 height: '100%',
    //                 cursor: 'pointer'
    //
    //             })
    //         }
    //     }
    //
    // }, [selected, notification])
    return (
        <div style={mediaBoxStyle}>

            {notification?.notificationType?.includes("health") &&
                <NotificationGridHealth notification={notification}  handleClick={handleClick} />
            }


            {notification?.notificationType?.includes("modeChanged") &&
                <NotificationGridModeChanged notification={notification} handleClick={handleClick} />
            }


            {(notification?.notificationDetails?.videoRequest ||  notification?.notificationDetails?.hyperlapseRequest) &&
                <NotificationGridMedia notification={notification} />
            }

            {notification?.requestDate &&
                <NotificationGridUpload notification={notification} />
            }

        </div>
    )
}

export default NotificationGridItem
