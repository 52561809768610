import {getDaysDifference, getSecondsDifference} from "../../../../../functions/formatting/formatDate";
import fetchJourneys from "../../../../../api/journeys/fetchJourneys";
import fetchDeviceDetails from "../../../../../api/device/fetchDeviceDetails";
import fetchJourney from "../../../../../api/journeys/fetchJourney";

export const fetchActivityData = async (dateRange, vehicle, theFleet, vehicles) => {

    if (dateRange){
        let loopIterations = getDaysDifference(dateRange[0], dateRange[1]);

        if (loopIterations === 1) loopIterations = 0

        const activity = [];
        const dns = [];
        let detailsArr = [];


        for (let i = 0; i <= loopIterations; i++) {

            const  startDate = new Date(dateRange[0]);

            let theDay;

            if (i > 0){
                theDay = new Date(startDate.setDate(startDate.getDate() + i));
            } else {
                theDay = startDate;
                const filtered = vehicles?.features?.filter(v => v?.properties?.fleetId === theFleet?.fleetId)

                for (let i = 0; i < filtered?.length; i++) {
                    const v = filtered[i];

                    if (v?.properties?.fleetId === theFleet?.fleetId){
                        dns.push(v?.properties?.dn);
                        const details = await fetchDeviceDetails(v);
                        detailsArr[v?.properties?.dn] = details[0]
                    }
                };
            }

            const dateString = [
                theDay.getFullYear(),
                ('0' + (theDay.getMonth() + 1)).slice(-2),
                ('0' + theDay.getDate()).slice(-2)
            ].join('-');

            // if vehicle selected create array and have it as only item within, then allows for us to
            // loop over array of vehicle/fleet vehicles here.

            if (vehicle){
                // if v selected only show single v data
                const journeysRaw = await fetchJourneys(vehicle?.properties?.dn, dateString);
                const journeys = journeysRaw.filter(j => j.endJpeg)
                let totalDur = 0;
                let totalDistance = 0;
                let shiftDur = 0;
                let totalStopped = 0;
                let totalIdleTime = 0;
                let totalTrueIdleTime = 0;

                if(journeys?.length > 0){

                    // add prev j endtime to j object here, so stopped time can be calculated
                    for (let i = 0; i < journeys?.length; i++) {

                        const j = journeys?.[i];

                        if (j?.duration){
                            const arr = j?.duration?.split(':');
                            const totalSeconds = (parseInt(arr[0])* 3600) + (parseInt(arr[1]) * 60) + (parseInt(arr[2]))
                            totalDur += parseInt(totalSeconds)
                        }
                        if(j?.distance){
                            totalDistance += j?.distance
                        }
                        totalTrueIdleTime += j?.trueIdling ? j?.trueIdling : 0;
                        totalIdleTime += j?.idling ? j.idling : 0;
                        totalStopped += j?.stopTime ? j?.stopTime : 0;
                    };


                    const st = new Date(journeys?.[0]?.start?.at).getTime() ;
                    const et = new Date(journeys?.[journeys?.length - 1]?.endTime).getTime();
                    shiftDur = (et - st) / 1000
                }

                activity.push({
                    date: dateString,
                    journeys: journeys,
                    index: i,
                    lastJourney: journeys?.[journeys?.length - 1],
                    firstJourney: journeys?.[0],
                    totalDur:totalDur,
                    totalDistance: totalDistance,
                    shiftDur: shiftDur,
                    totalStopped: totalStopped,
                    totalIdleTime: totalIdleTime,
                    totalTrueIdleTime: totalTrueIdleTime
                });
            } else {
                //show fleet level data

                const vDataArr = [];


                for (let i = 0; i < dns?.length; i++) {
                    // wait for the promise to resolve before advancing the for loop
                    const dn = dns[i];
                    const journeysRaw = await fetchJourneys(dn, dateString);

                    const journeys = journeysRaw.filter(j => j.endJpeg);
                    let totalDur = 0;
                    let totalDistance = 0;
                    let shiftDur = 0;
                    let totalStopped = 0;
                    let co2Per = detailsArr?.[dn]?.vehicleDvlaData?.VehicleRegistration?.Co2Emissions;
                    let totalIdleTime = 0;
                    let totalTrueIdleTime = 0;


                    if(journeys?.length > 0){

                        // add prev j endtime to j object here, so stopped time can be calculated

                        for (let i = 0; i < journeys?.length; i++) {

                            //fetch journey to calculate idle time
                            const j = journeys?.[i];



                            if (j?.duration){
                                const arr = j?.duration?.split(':');
                                const totalSeconds = (parseInt(arr[0])* 3600) + (parseInt(arr[1]) * 60) + (parseInt(arr[2]))
                                totalDur += parseInt(totalSeconds)
                            }
                            if(j?.distance){
                                totalDistance += j?.distance
                            }

                            totalIdleTime += j?.idling ? j?.idling : 0;
                            totalTrueIdleTime += j?.trueIdling ? j?.trueIdling : 0;
                            totalStopped += j?.stopTime ? j?.stopTime : 0;

                        };


                        const st = new Date(journeys?.[0]?.start?.at).getTime() ;
                        const et = new Date(journeys?.[journeys?.length - 1]?.endTime).getTime();
                        shiftDur = (et - st) / 1000

                        vDataArr.push({
                            dn: dn,
                            journeys: journeys,
                            index: i,
                            lastJourney: journeys?.[journeys?.length - 1],
                            firstJourney: journeys?.[0],
                            totalDur,
                            totalDistance,
                            shiftDur,
                            totalStopped,
                            co2Per,
                            totalIdleTime,
                            totalTrueIdleTime
                        });
                    }
                }
                activity.push({
                    date: dateString,
                    vData: vDataArr
                });

                console.log(activity)
            }
        }
        return activity
    }

}
