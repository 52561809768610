import React, {useContext} from 'react';
import DeviceDetailsModal from "./deviceDetailsModal";
import {Dialog} from "primereact/dialog";
import mainContext from "../../../contexts/mainContext";
import indexContext from "../../../contexts/indexContext";
import {formatDateMediaGrid, getDaysDifference} from "../../../../functions/formatting/formatDate";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {MANAGEMENT_PLATFORM} from "../../../../Constants";
import {Button} from "primereact/button";

const DeviceDetailsModalParent = ({}) => {

    const {winWidth} = useContext(indexContext)
    const {displayDeviceDetailsModal, closeModal, deviceDetails} = useContext(mainContext);

    const DeviceDetailsModalHeader = () => {
        return (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div>
                    Settings - {deviceDetails?.registration}
                    {deviceDetails &&
                        <div style={{fontSize:'12px', fontWeight: 'normal', display: 'flex', alignItems: 'center'}}>
                            Last connected: {formatDateMediaGrid(deviceDetails?.lastConnection)}
                            {getDaysDifference(deviceDetails?.lastConnection, new Date()) > 10 &&
                                <div style={{marginLeft: '10px', width: '8px', height: '8px', background: 'red', borderRadius: '5px'}} />
                            }
                        </div>
                    }
                </div>

                {fetchUserLevel() === 'superuser' &&
                    <div style={{marginRight: '10px'}}>


                        <Button className={"p-button p-button-sm"} label={"Config"} style={{background: 'var(--v-list-button-selected)',
                            color: 'var(--v-list-button-text-selected)'}}
                                onClick={() => window.open(MANAGEMENT_PLATFORM + 'device/' + deviceDetails?.dn, '_blank')}
                        />

                    </div>
                }
            </div>
        )
    }

    return (
        <Dialog visible={displayDeviceDetailsModal} position={'center'} style={{width: winWidth >= 800 ? '40vw' : '100vw',
            height: winWidth >= 800 ? '90%' : '100%', maxHeight:'100%'}}
                onHide={() => closeModal('displayDeviceDetailsModal')} modal={false}
                header={<DeviceDetailsModalHeader />}>
            <DeviceDetailsModal />
        </Dialog>
    )
}
export default DeviceDetailsModalParent
