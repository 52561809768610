import React, {useContext, useEffect, useState} from 'react';
import mainContext from "../../../../contexts/mainContext";
import {Chart} from "primereact/chart";
import twenty from "../../../../../svgs/speedLimits/20-mph-limit.jpg"
import thirty from "../../../../../svgs/speedLimits/30-mph-limit.jpg"
import fourty from "../../../../../svgs/speedLimits/40-mph-limit.jpg"
import fifty from "../../../../../svgs/speedLimits/50-mph-limit.jpg"
import sixty from "../../../../../svgs/speedLimits/60-mph-limit.jpg"
import seventy from "../../../../../svgs/speedLimits/70-mph-limit.jpg"

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {faInfoCircle} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "primereact/tooltip";


const SpeedingChart = ({    }) => {
    
    const minData = 100;
    const minReplace = null;

    const {event} = useContext(mainContext);

    const [showChart, setShowChart] = useState();
    const [chartData, setChartData] = useState();


    const struct = [
        {
            key: 's0',
            values: [0,1,2,3,4],
            display: "0-5mph",
            count: event?.speedData?.[0]?.['s0'] > minData ? event?.speedData?.[0]?.['s0'] : minReplace

        },
        {
            key: 's5',
            values: [5,6,7,8,9,10],
            display: "5-10mph",
            value: event?.speedData?.[0]?.['s5'] > minData ? event?.speedData?.[0]?.['s5'] : minReplace
        },
        {
            key: 's10',
            values: [11,12,13,14,15],
            display: "10-15mph",
            count: event?.speedData?.[0]?.['s10'] > minData ? event?.speedData?.[0]?.['s10'] : minReplace
        },
        {
            key: 's15',
            values: [16,17,18,19,20],
            display: "15-20mph",
            count: event?.speedData?.[0]?.['s15'] > minData ? event?.speedData?.[0]?.['s15'] : minReplace
        },
        {
            key: 's20',
            values: [21,22,23,24,25],
            display: "20-25mph",
            count: event?.speedData?.[0]?.['s20'] > minData ? event?.speedData?.[0]?.['s20'] : minReplace
        },
        {
            key: 's25',
            values: [26,27,28,29,30],
            display: "25-30mph",
            count: event?.speedData?.[0]?.['s25'] > minData ? event?.speedData?.[0]?.['s25'] : minReplace
        },

        {
            key: 's30',
            values: [31,32,33,34,35],
            display: "30-35mph",
            count: event?.speedData?.[0]?.['s30'] > minData ? event?.speedData?.[0]?.['s30'] : minReplace
        },
        {
            key: 's35',
            values: [36,37,38,39,40],
            display: "35-40mph",
            count: event?.speedData?.[0]?.['s35'] > minData ? event?.speedData?.[0]?.['s35'] : minReplace
        },
        {
            key: 's40',
            values: [41,42,43,44,45],
            display: "40-45mph",
            count: event?.speedData?.[0]?.['s40'] > minData ? event?.speedData?.[0]?.['s40'] : minReplace
        },
        {
            key: 's45',
            values: [46,47,48,49,50],
            display: "45-50mph",
            count: event?.speedData?.[0]?.['s45'] > minData ? event?.speedData?.[0]?.['s45'] : minReplace
        },
        {
            key: 's50',
            values: [51,52,53,54,55],
            display: "50-55mph",
            count: event?.speedData?.[0]?.['s50'] > minData ? event?.speedData?.[0]?.['s50'] : minReplace
        },
        {
            key: 's55',
            values: [56,57,58,59,60],
            display: "55-60mph",
            count: event?.speedData?.[0]?.['s55'] > minData ? event?.speedData?.[0]?.['s55'] : minReplace
        },
        {
            key: 's60',
            values: [61,62,63,64,65],
            display: "60-65mph",
            count: event?.speedData?.[0]?.['s60'] > minData ? event?.speedData?.[0]?.['s60'] : minReplace
        },
        {
            key: 's65',
            values: [66,67,68,69,70],
            display: "65-70mph",
            count: event?.speedData?.[0]?.['s65'] > minData ? event?.speedData?.[0]?.['s65'] : minReplace
        },
        {
            key: 's70',
            values: [71,72,73,74,75],
            display: "70-75mph",
            count: event?.speedData?.[0]?.['s70'] > minData ? event?.speedData?.[0]?.['s70'] : minReplace
        },

        {
            key: 's75',
            values: [76,77,78,79,80],
            display: "75-80mph",
            count: event?.speedData?.[0]?.['s75'] > minData ? event?.speedData?.[0]?.['s75'] : minReplace
        },
        {
            key: 's80',
            values: [81,82,83,84,85],
            display: "80-85mph",
            count: event?.speedData?.[0]?.['s80'] > minData ? event?.speedData?.[0]?.['s80'] : minReplace
        },
    ]



    const getSpeedLimitImage = (speed) => {
        switch (speed) {
            case 20: return twenty;
            case 30: return thirty;
            case 40: return fourty;
            case 50: return fifty;
            case 60: return sixty;
            case 70: return seventy;
        }
    }


    const barAvatar = {
        id: 'customImage',
        beforeDatasetDraw: async (chart) => {
            const {ctx, data} = chart;

            const limitBar = struct.filter(entry => entry.values?.includes(event?.speedData?.[0]?.maxspeed))
            data.labels.forEach((label, index) => {
                if (label === limitBar[0].display) {
                    const chartImage = new Image();
                    chartImage.src = getSpeedLimitImage(limitBar[0]?.values?.[4]);
                    const xPos = chart.getDatasetMeta(0).data[index].x
                    const yPos = chart.getDatasetMeta(0).data[index].y
                    ctx.drawImage(chartImage, xPos-12.5, yPos - 27, 25, 25)
                }
            });
        }
    }





    var options = {
        skipNull: true,
        tooltip: {
            enabled: false,
        },
        layout: {
            padding: {
                right: 20,
                top: 25
            },
        },
        scales: {
            y: {
                ticks: {
                    display: false
                }
            }
        },
        options: {
            legend: false
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip:{
                enabled: false
            }
        }
    };


    useEffect(() => {
        if (event?.speedData?.[0]?.euclidDist){


            let theIndex;
            let euclidIndex;

            let arr = event?.speedData?.[0]?.euclidDist ? Object.values(event?.speedData?.[0]?.euclidDist) : [];
            let min = arr.sort()?.[0]
            if (min > 20) min = null;
            const key = Object?.keys(event?.speedData?.[0]?.euclidDist).find(key => event?.speedData?.[0]?.euclidDist[key] === min)

            let maxCount = 0;
            struct.forEach((entry, index) => {

                if (entry?.count > maxCount) maxCount = entry.count

                const theSpeed = parseInt((event?.speed / 100 * 0.621371).toFixed(0))
                if (entry.values.includes(theSpeed)){
                    theIndex = index
                }
                if (entry.key === key){
                    euclidIndex = index
                }

            });

            let bgArr = struct.map(function (val, index) {
                if (index === euclidIndex) return 'green'
                return 'gray'
            })


            setChartData({

                labels: struct?.reduce((acc, curVal) => acc.concat(curVal.display), []),
                datasets: [{
                    label: 'Number of drivers travelling at speed range',
                    data: struct?.reduce((acc, curVal) => acc.concat(curVal.count), []),
                    backgroundColor: bgArr,
                    datalabels: {
                        labels: {
                            title: null
                        }
                    }
                },
                {
                    label: 'Speed of event',
                    data: struct?.reduce((acc, curVal) =>
                        acc.concat(curVal.values?.includes(parseInt((event?.speed / 100 * 0.621371).toFixed(0))) ? maxCount*1.2 : null), []),
                    backgroundColor: 'red',
                    barPercentage: 0.3,
                    categoryPercentage: 0.4,
                    datalabels: {
                        color: 'red',
                        formatter: function(value, context) {
                            return value > 0 ? (event?.speed / 100 * 0.621371).toFixed(0) + ' mph' : null;
                        },
                        anchor: 'end',
                        align: 'top'
                    }
                }]

            });
        }

    }, [event])


    useEffect(() => {
        setShowChart(struct?.reduce((acc, curVal) => {
            return curVal?.count > 1 ? acc+1 : acc
        }, 0) >= 4)
    }, [chartData])



    return (
            <React.Fragment>
                {showChart &&

                    <React.Fragment>

                        <h4 style={{marginLeft: 'none!important'}}>
                            Typical vehicle speed for location&nbsp;
                            <FontAwesomeIcon icon={faInfoCircle} className={"speedChartTooltip"}/>
                            <Tooltip target={".speedChartTooltip"}>
                                <div style={{width: '200px'}}>
                                    The below chart shows the speed that other vehicles travel along this section of road.
                                    The green bar shows the typical speed when normalised for traffic conditions,
                                    the red bar is the speed the vehicle was travelling during this event
                                </div>
                            </Tooltip>
                        </h4>
                        <Chart type="bar" data={chartData} options={options} plugins={[barAvatar, ChartDataLabels]}
                               redraw={true}/>

                    </React.Fragment>

                }
            </React.Fragment>

    )

}

export default SpeedingChart