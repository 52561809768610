import React, {useContext, useRef, useState} from 'react';
import {ConfirmPopup} from "primereact/confirmpopup";
import {InputText} from "primereact/inputtext";
import {Toast} from "primereact/toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationPlus, faTrash} from "@fortawesome/pro-regular-svg-icons";
import postAddPoi from "../../../../../api/places/postAddPoi";
import {TOAST_DURATION} from "../../../../../Constants";
import {Dropdown} from "primereact/dropdown";
import {catOptions} from "../../places/catOptions";
import {optionTemplate} from "../../../../../functions/templates/optionTemplate";
import mainContext from "../../../../contexts/mainContext";

const AddPlaceButton = ({lat, lng, fleetId}) => {


    const {toast} = useContext(mainContext)

    const [visible, setVisible] = useState(false);
    const [name, setName] = useState('');
    const [cat, setCat] = useState();
    const id = useRef(Math.random())



    const popupBody = () => {
        return (
            <div>
                <div style={{marginBottom: '5px',fontWeight: 'bold', fontSize: '14px'}}>Add Place of Interest</div>
                <InputText value={name} onChange={(e) => setName(e.target.value)} placeholder="Place name"/>
                <br />
                Category:
                <Dropdown id="category" options={catOptions} value={cat}
                          onChange={(e) => setCat(e.target.value)}
                          valueTemplate={(e) => optionTemplate(e, 'desktop')}
                          itemTemplate={(e) => optionTemplate(e, 'desktop')}
                          placeholder="Category" optionLabel="display"/>
            </div>
        )
    }

    const addPoi = async () => {

        if (name?.length > 2 && fleetId && lat && lng){
            const data = {
                fleetId,
                lat,
                lng,
                name,
                cat: cat.display
            }
            const res = await postAddPoi(data);

            if (res.mod === 'added'){
                toast.current.show({ severity: 'success', summary: 'All done', detail: name + ' added as a place',
                    life: TOAST_DURATION });
            } else {
                toast.current.show({ severity: 'error', summary: 'Whoops!', detail: 'Something has gone wrong!', life: TOAST_DURATION });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Not saved', detail: 'Name is required', life: TOAST_DURATION });
        }

        setName('');
    }



    return (
        <React.Fragment>
            {/*<span style={{marginLeft: '10px'}} id={id?.current} onClick={(e) => {*/}
            {/*    setVisible(true)*/}
            {/*}}>*/}
            {/*     <FontAwesomeIcon icon={faLocationPlus} />*/}
            {/*</span>*/}



            <ConfirmPopup target={document.getElementById(id?.current)} visible={visible}
                          onHide={() => setVisible(false)}
                          icon={<FontAwesomeIcon icon={faLocationPlus} style={{fontSize: "18px"}}/>}
                          message={popupBody}
                          accept={addPoi}
                          reject={() => {
                              setName('')}
                          } />

        </React.Fragment>
    )
}

export default AddPlaceButton
