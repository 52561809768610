import React, {useContext, useEffect, useState} from 'react';
import PlaceModalMap from "./placeModalComponents/placeModalMap";
import {InputText} from "primereact/inputtext";
import mainContext from "../../../contexts/mainContext";
import {Dropdown} from "primereact/dropdown";
import {faBuildings, faTrashAlt} from "@fortawesome/pro-regular-svg-icons";
import {optionTemplate} from "../../../../functions/templates/optionTemplate";
import {selectedOptionTemplate} from "../../../../functions/templates/selectedOptionTemplate";
import {applySpacing} from "../../../../functions/formatting/applySpacing";
import fetchReverseGeocode from "../../../../api/fetchReverseGeocode";
import postDeletePoi from "../../../../api/places/postDeletePoi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {catOptions} from "../../panelContent/places/catOptions";


const PlaceModal = ({placeSelected, saveChanges, fleet, handleShowPlaceModal, handleRefreshPois}) => {


    const [newCoords, setNewCoords] = useState(placeSelected?.location?.coordinates);
    const [placeName, setPlaceName] = useState(placeSelected?.name);
    const [placeCategory, setPlaceCategory] = useState();
    const [location, setLocation] = useState();
    const [radius, setRadius] = useState(placeSelected? placeSelected?.radius : 200);

    const handleNewCoords = (coords) => setNewCoords(coords);
    const handleLocation = (loc) => setLocation(loc);







    const savePlaceChanges = async () => {

        const data = {
            name: placeName,
            fleetId: fleet.fleetId,
            lat: newCoords[1],
            lng: newCoords[0],
            cat: placeCategory?.display,
            radius: radius
        }
        await saveChanges(data, placeSelected)
    }

    useEffect(async () => {
        if (placeSelected){

            setPlaceCategory(await catOptions.filter(cat => cat.display === placeSelected?.cat)?.[0] )
            const loc = await fetchReverseGeocode(placeSelected?.location?.coordinates[1], placeSelected?.location?.coordinates[0])
            setLocation(loc?.features?.[0]?.properties?.address);
        }
    }, [placeSelected])

const LocationStr = () => {
        return (
            <React.Fragment>
                {location &&
                    <React.Fragment>
                        {applySpacing(location?.house_number)}
                        {applySpacing(location?.building)}
                        {applySpacing(location?.road)}
                        {location?.village && <React.Fragment>{applySpacing(location?.village)}</React.Fragment>}
                        {location?.town && <React.Fragment>{applySpacing(location?.town)}</React.Fragment>}
                        {location?.city && <React.Fragment>{applySpacing(location?.city)}</React.Fragment>}
                        {location?.hamlet && <React.Fragment>{applySpacing(location?.hamlet)}</React.Fragment>}
                        {location?.postcode}
                    </React.Fragment>
                }

            </React.Fragment>
        )
}

    return (
        <React.Fragment>
            <div style={{display: 'flex', width: '100%', height: '55vh'}}>
                <PlaceModalMap placeSelected={placeSelected} handleNewCoords={handleNewCoords} handleLocation={handleLocation}
                location={location} radius={radius}/>
            </div>

            <div style={{textAlign:'center', margin: '10px 0px'}}>
                Click a place on the map to position the POI
            </div>


                <div>

                    <div style={{display: 'flex', marginBottom: '10px'}}>
                        <div style={{width: '100px'}}>Location:</div>
                        <div className="p-inputtext" style={{flex:1}}>
                            <LocationStr />
                        </div>
                    </div>


                    <div style={{display: 'flex', marginBottom: '10px'}}>
                        <div style={{width: '100px'}}>Place name:</div>
                        <InputText id="name" value={placeName} placeholder="Place name"
                                   onChange={(e) => setPlaceName(e.target.value)}/>
                    </div>
                    <div style={{display: 'flex', marginBottom: '10px'}}>

                        <div style={{width: '100px'}}>Category:</div>
                        <Dropdown id="category" options={catOptions} value={placeCategory}
                                  onChange={(e) => setPlaceCategory(e.target.value)}
                                  valueTemplate={(e) => optionTemplate(e, 'desktop')}
                                  itemTemplate={(e) => optionTemplate(e, 'desktop')}
                                  placeholder="Category" optionLabel="display"/>

                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '100px'}}>Radius:</div>
                        <Dropdown id="category" options={[100,200,300,400,500]} value={radius}
                                  onChange={(e) => setRadius(e.target.value)}

                                  placeholder="Radius" />
                    </div>

                </div>
            <div style={{textAlign: 'right'}}>
                <button className="p-button p-button-sm p-button-secondary" onClick={async (e) => {
                    e.stopPropagation();
                    await postDeletePoi(placeSelected);
                    handleShowPlaceModal(false);
                    handleRefreshPois(true)

                }} style={{marginRight: '10px'}}>
                    <span className="p-button-label">
                        <FontAwesomeIcon  icon={faTrashAlt} />
                    </span>
                </button>

                <button className="p-button p-button-sm p-button-danger" onClick={() => handleShowPlaceModal(false)}
                        style={{marginRight: '10px'}}>
                    <span className="p-button-label">Cancel</span>
                </button>

                <button className="p-button p-button-sm p-button-success" onClick={savePlaceChanges}>
                    <span className="p-button-label">Save</span>
                </button>
            </div>

        </React.Fragment>
    )
}
export default PlaceModal
