// //LIVE CONSTANTS

export const API_URL = "https://api.myfleetlive.solutions"
export const MANAGEMENT_PLATFORM = 'https://mflconnect.myfleetlive.co.uk/'
export const LIVESTREAM_URL = "https://stream.myfleetlive.co.uk"
export const NOTIFICATIONS_API_URL = 'https://notifications.mflstaging.co.uk'
export const NOTIFICATIONS_CALLBACK_URL = 'https://notifications.mflstaging.co.uk/MflConnectWebhookLive';

// //STAGING CONSTANTS
//
// export const API_URL = "https://api.mfldev.co.uk"
// export const MANAGEMENT_PLATFORM = 'https://mflconnectstaging.fleetfocus.co.uk/'
// export const LIVESTREAM_URL = "https://liveview.mfldev.co.uk";
// export const NOTIFICATIONS_API_URL = 'https://notifications.mfldev.co.uk';
// export const NOTIFICATIONS_CALLBACK_URL = 'https://notifications.mfldev.co.uk/MflConnectWebhook';

//CONSTANT CONSTANTS
export const TOAST_DURATION = 5000;
export const DEFAULT_UPLOAD_TIME_LIMIT = 30;
export const KEY = "15135b02b9ab514891bc828c84683d34";
export const MIN_DISTANCE = 0.2;
export const MODEL_NUMBER_ARRAY = [
    {
        modelNoDb: "2CN4H01EU",
        modelNoDisplay: 'MFL-2CN4H01-EU',
        channels: 2
    },
    {
        modelNoDb: '2CN4H02EU',
        modelNoDisplay: 'MFL-2CN4H02-EU',
        channels: 2
    },
    {
        modelNoDb: '2CN4H02US',
        modelNoDisplay: 'MFL-2CN4H02-US',
        channels: 2
    },
    {
        modelNoDb: '5CN4H02EU',
        modelNoDisplay: 'MFL-5CN4H02-EU',
        channels: 5
    },
    {
        modelNoDb: '5CN4H02US',
        modelNoDisplay: 'MFL-5CN4H02-US',
        channels: 5
    },
    {
        modelNoDb: '8CA4H01EU',
        modelNoDisplay: 'MFL-8CA4H01-EU',
        channels: 8
    },
    {
        modelNoDb: '8CA4H01US',
        modelNoDisplay: 'MFL-8CA4H01-US',
        channels: 8
    },
    {
        modelNoDb: '2DN4H02EU',
        modelNoDisplay: 'MFL-2DN4H02-EU',
        channels: 2
    },
    {
        modelNoDb: '3DN4H03EU',
        modelNoDisplay: 'MFL-3DN4H03-EU',
        channels: 3
    },
    {
        modelNoDb: '3DA4H03EU',
        modelNoDisplay: 'MFL-3DA4H03-EU',
        channels: 3
    }
];

export const AdasEventTypes = [
    'collision warning', 'lane departure', 'tailgating', 'pedestrian warning',
    'frequent lane change alarm', 'road sign vilate alarm', 'harsh acceleration', 'harsh braking',
    'road sign recognition driverBehaviour', 'collision warning', 'tailgating',  'Lane Departure (L)',
    'Lane Departure (R)', 'collision warning',


];

export const DmsEventTypes = [
    'driver fatigue', 'mobile phone', 'smoking', 'distraction', 'driver abnormal alarm',
    'driver change driverBehaviour', 'drowsiness', 'fatigue', 'camera cover', 'distraction',
    'seatbelt', 'no driver',  'food or drink', 'driver shift',  'driver back',
    'driver id identified successfully',  'driver id identified failed', 'cannot detect human face'
]


export const videoUploadOrder = ["Front", "Rear", "Nearside", "Offside"]