import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBusinessTime, faCar, faClock, faLocationDot, faRuler, faTimer} from "@fortawesome/pro-light-svg-icons";
import {formatDateTimesheetReport} from "../../../../../functions/formatting/formatDate";
import indexContext from "../../../../contexts/indexContext";
import {faGauge} from "@fortawesome/pro-regular-svg-icons";

const MovementReportTableHeader = ({}) => {

    const {darkMode} = useContext(indexContext);


    return (
        <div style={{
            display: 'grid', gridTemplateColumns: '10% 10% 10% 10% 15% 15% 30% ',
            borderBottom: '1px solid var(--surface-border)',
            height: '30px', marginTop: '12px', pageBreakInside: 'auto', fontWeight: 'bold',
            textAlign: 'center'
        }} className="reportTable">


            <div style={{lineHeight: '30px', paddingLeft: '10px'}}>
                #
            </div>
            <div style={{lineHeight: '30px'}}>
                <FontAwesomeIcon icon={faClock}/>
                &nbsp;
                Time
            </div>
            <div style={{lineHeight: '30px'}}>
                <FontAwesomeIcon icon={faGauge}/>
                &nbsp;Speed
            </div>
            <div style={{lineHeight: '30px'}}>
                <FontAwesomeIcon icon={faGauge}/>
                &nbsp;Status
            </div>

            <div style={{lineHeight: '30px'}}>
                <FontAwesomeIcon icon={faRuler}/>
                &nbsp;
                Distance
            </div>
            <div style={{lineHeight: '30px'}}>
                <FontAwesomeIcon icon={faRuler}/>
                &nbsp;
                Cumulative Distance
            </div>
            <div style={{lineHeight: '30px'}}>
                <FontAwesomeIcon icon={faLocationDot}/>
                &nbsp;Place
            </div>

        </div>
    )
}
export default MovementReportTableHeader
