import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import VehicleListTemplate from "./vehicleListTemplate";
import {DataScroller} from "primereact/datascroller";
import LoadingSpinner from "../loadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUp} from "@fortawesome/pro-regular-svg-icons";
import {getDrivingStatus} from "../../../functions/drivingStatus";
import vehicleListFilters from "./vehicleListFilters";
import mainContext from "../../contexts/mainContext";
import DeviceNotificationModalParent from "../modals/deviceNotificationModal/deviceNotificationModalParent";

const VehicleList = ({addChip, filteredVehicles, displayType, showFilters}) => {


    const {vehicleFilterParams, vehicle} = useContext(mainContext)

    const [mounted, setMounted] = useState(false)
    const [panelData, setPanelData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const loaderRef = useRef(null);
    const [showLoadMore, setShowLoadMore] = useState(false)

    const [showDeviceNotificationModal, setShowDeviceNotificationModal] = useState(false);
    const [deviceForNotifications, setDeviceForNotifications] = useState();


    const triggerChange = useRef(true);
    const index = useRef(100)


    const handleShowDeviceNotificationModal = val => setShowDeviceNotificationModal(val);
    const handleDeviceForNotifications = val => setDeviceForNotifications(val)

    const loadMore = async (e) => {
        const newData = filteredVehicles?.features.slice(index?.current, index?.current+100);
        index.current += 100
        setPanelData((prevData) => [...prevData, ...newData])
    }


    function checkInView(elem,partial)
    {
        var container = document.getElementById("vList")?.getBoundingClientRect();
        var contHeight = container.height;
        var contTop = container.scrollTop;
        var contBottom = contTop + contHeight

        var elemTop = elem.getBoundingClientRect().top - container.top;
        var elemBottom = elemTop + elem.getBoundingClientRect().height;

        var isTotal = (elemTop >= 0 && elemBottom <=contHeight);
        var isPart = ((elemTop < 0 && elemBottom > 0 ) || (elemTop > 0 && elemTop <= container.height)) && partial ;

        return  isTotal  || isPart ;
    }

    useEffect(() => {
        if (vehicle && mounted){

            const shown = panelData.filter(v => v?.properties?.dn === vehicle?.properties?.dn)

            if (shown?.length > 0){
                const vListItem = document.getElementById( 'vList_' + vehicle.properties?.dn);
                const vList = document.getElementById("vList");
                const visible = checkInView(vListItem, false);
                if (!visible){
                    vList.scroll({
                        top: vListItem.offsetTop,
                        behavior: 'smooth'
                    });
                }

            } else {
                setPanelData([vehicle])
            }

        } else {
            setPanelData(filteredVehicles?.features.slice(0, index.current))
        }

        triggerChange.current = true;
    }, [vehicle]);

    useEffect(() => {
        if (mounted){
            setPanelData(filteredVehicles?.features.slice(0, index.current))
        }
    }, [filteredVehicles]);

    useEffect(() => {
        if (mounted){
            index.current = 100;
            setPanelData(filteredVehicles?.features.slice(0, 100))
        }
    }, [vehicleFilterParams])

    useEffect(() => {
        setMounted(true)
        return () => {
            setPanelData(null);
            setMounted(false)
        }
    }, []);


    return (
        <div style={{height: 'calc(100vh - 210px)'}}>
            <div style={{height: '100%',overflowY: 'auto', position: 'relative'}} id="vList"

                 onScroll={async (e) => {

                     if (showLoadMore && (e.nativeEvent.target.scrollTop / e.nativeEvent.target.scrollHeight) * 100 < 55) {
                         await setShowLoadMore(false)

                     }
                     if (!showLoadMore && ((e.nativeEvent.target.scrollTop / e.nativeEvent.target.scrollHeight) * 100 > 55)) {
                         await setShowLoadMore(true)
                         await loadMore();
                     }
                 }}


            >

                {panelData?.map((v, i) => (
                    <React.Fragment key={v?.properties?.dn}>
                        {/*{(vehicleFilterParams?.drivingStatus.has(getDrivingStatus(v?.properties?.lastHeartbeat,*/}
                        {/*    v?.properties?.lastLocation?.ac, new Date().getTime()))) &&*/}
                        {/*        <VehicleListTemplate d={v} addChip={addChip} displayType={displayType}/>*/}
                        {/*}*/}

                        <VehicleListTemplate d={v} addChip={addChip} displayType={displayType}
                                             handleShowDeviceNotificationModal={handleShowDeviceNotificationModal}
                                             showDeviceNotificationModal={showDeviceNotificationModal}
                                             handleDeviceForNotifications={handleDeviceForNotifications}
                                             triggerChange={triggerChange} />

                    </React.Fragment>
                ))}

                <div ref={loaderRef} style={{height: '90px'}} >
                    {isLoading &&
                        <div style={{ display: "flex", justifyContent: "center",height: '100px', alignItems: 'center'}}>Loading</div>
                    }
                </div>
            </div>
            <DeviceNotificationModalParent showDeviceNotificationModal={showDeviceNotificationModal}
                                           handleShowDeviceNotificationModal={handleShowDeviceNotificationModal}
                                            deviceForNotifications={deviceForNotifications}
            />
        </div>


    )

}

export default VehicleList


//store a refresh var as a dn on this
// access to read & write on v list template, if changes to same dn as template refresh health then reset to null
// access to write on the modal, and on mark read write as the dn
