import React, {useContext, useEffect, useState} from 'react';
import ReportsDatePicker from "./reportsDatePicker";
import {Dropdown} from "primereact/dropdown";
import indexContext from "../../../contexts/indexContext";
import mainContext from "../../../contexts/mainContext";
import reportsContext from "../../../contexts/reportsContext";
import {fetchAdminFleets} from "../../../../functions/fetchAdminFleets";
import {fetchUserLevel} from "../../../../functions/fetchUserLevel";
import {getStoreData} from "../../../../functions/db";
import fetchJourneys from "../../../../api/journeys/fetchJourneys";
import {dateString} from "../../../../functions/formatting/formatDate";

const ReportsParameterSelection = ({handleGenerateReport}) => {


    const {fleets} = useContext(indexContext);
    const {vehicle} = useContext(mainContext)
    const {dateRange, reportType, handleDateRange, theFleet, handleTheFleet, theVehicle, handleTheVehicle,
        handleTheJourney, theJourney} = useContext(reportsContext)
    const [fleetsDropdown, setFleetsDropdown] = useState([]);
    const [vehicles, setVehicles] = useState();
    const [journeyOptions, setJourneyOptions] = useState([]);


    useEffect(async () => {
        if (theVehicle && reportType?.type?.includes('journey')){

            let r;
            if (dateRange?.[0]){
                r = await fetchJourneys(theVehicle.properties.dn, dateString(new Date()));
            } else {
                 r = await fetchJourneys(theVehicle.properties.dn, dateString(dateRange));
            }

            setJourneyOptions(r)
        }
    }, [theVehicle, dateRange])


    useEffect(async () => {
        const v = await getStoreData('vehicles');
        setVehicles(v)
    }, []);

    useEffect(async () => {
        if (fetchUserLevel() === 'fleet'){
            const fleetsAllowed = fetchAdminFleets();
            const filtered = await fleets.filter(fleet => fleetsAllowed?.includes(fleet?.fleetId));
            setFleetsDropdown(filtered)

            if (filtered?.length === 1){
                handleTheFleet(filtered?.[0])
            }
        } else {
            setFleetsDropdown(fleets)
        }


    }, [fleets])


    return (
        <React.Fragment>
            <ReportsDatePicker handleDateRange={handleDateRange} dateRange={dateRange} />
            <br/>


            {reportType?.type?.includes('fleet') && fleetsDropdown?.length > 1 ?
                <Dropdown options={fleetsDropdown} optionLabel="fleetName" filter filterBy="fleetName"
                          style={{
                              height: '46px',
                              width: '202px',
                              border: 'none',
                              borderRadius: '0px',
                              lineHeight: '35px',
                              marginRight: '10px'
                          }}
                          placeholder="Select fleet" value={theFleet}
                          onChange={(e) => {
                              handleTheFleet(e.value)
                          }}
                />
            :
                <React.Fragment>{theFleet?.fleetName ? theFleet?.fleetName : theFleet?.fleetId}</React.Fragment>

            }

            {reportType?.type?.includes('vehicle') &&
                <Dropdown options={vehicles} optionLabel="properties.registration" filter
                          filterBy="properties.registration"
                          style={{
                              height: '46px',
                              width: '202px',
                              border: 'none',
                              borderRadius: '0px',
                              lineHeight: '35px',
                              marginRight: '10px'
                          }}
                          placeholder="Select vehicle" value={theVehicle}
                          onChange={(e) => handleTheVehicle(e.value)}
                />

            }


            {reportType?.type?.includes('journey') &&
                <React.Fragment>
                    <br/><br/>
                    <Dropdown options={journeyOptions} optionLabel="_id"
                              style={{
                                  height: '46px',
                                  width: '202px',
                                  border: 'none',
                                  borderRadius: '0px',
                                  lineHeight: '35px',
                                  marginRight: '10px'
                              }}
                              disabled={!theVehicle}
                              placeholder="Select journey" value={theJourney}
                              onChange={(e) => handleTheJourney(e.value)}
                    />
                </React.Fragment>

            }



            <br/><br/>



            <button className="p-button p-button-sm " onClick={() => handleGenerateReport(true)}
                    disabled={((reportType?.type?.includes('vehicle') && !theVehicle) ||
                        (reportType?.type?.includes('fleet') && !theFleet)) || !dateRange}
                    style={{background: 'var(--v-list-button-selected)', color: 'var(--v-list-button-text-selected)'}}>
                Generate Report
            </button>

        </React.Fragment>
    )
}

export default ReportsParameterSelection
