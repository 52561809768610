import React, {useContext, useEffect, useState} from 'react';
import {Dropdown} from "primereact/dropdown";
import {uCaseFirst} from "../../../../functions/formatting/uCaseFirst";
import {Checkbox} from "primereact/checkbox";
import abovePanelContext from "../../../contexts/abovePanelContext";

const EventTagFilters = ({tagsToDisplay, disableDateChange, handleEventParams, eventParams}) => {


    const [popupCurTagFilters, setPopupCurTagFilters] = useState(eventParams?.length > 0 ? eventParams : []);


    const handleCheckBoxClick = (val) => {
        if (!disableDateChange) {
            let newFilters = JSON.parse(JSON.stringify(popupCurTagFilters))

            if (popupCurTagFilters?.includes(val)) {
                const index = newFilters.indexOf(val);
                if (index > -1) newFilters.splice(index, 1);
            } else {
                newFilters.push(val)
            }
            setPopupCurTagFilters(newFilters)
            handleEventParams(newFilters);
        }
    }

    const itemTemplate  = (item) => {
        return (
            <div onClick={(e) => handleCheckBoxClick(item?._id)}>
                <Checkbox id={item?._id} onChange={(e) => handleCheckBoxClick(item?._id)}
                          checked={popupCurTagFilters?.includes(item?._id)}/>
                &nbsp;
                <label htmlFor={item?._id} className="ml-2">
                    {item.tagDetails?.[0]?.displayName ? item.tagDetails?.[0]?.displayName : item?._id}&nbsp;
                    <span style={{
                        color: 'var(--text-color-secondary)',
                        verticalAlign: 'super',
                        fontSize: '12px'
                    }}>{item?.tags}</span>


                    {/*{item.tagDetails?.[0]?.origin &&*/}
                    {/*    <React.Fragment>*/}
                    {/*        {item.tagDetails?.[0]?.origin === 'cloud AI' ?*/}
                    {/*            <FontAwesomeIcon icon={faCloud} />*/}
                    {/*        :*/}
                    {/*            <FontAwesomeIcon icon={faMicrochipAi} />*/}
                    {/*        }*/}
                    {/*    </React.Fragment>*/}
                    {/*}*/}



                </label>


            </div>
        )
    }


    const dropdownPlaceholder = (val) => {
        let str = uCaseFirst(val.replace(/([a-z])([A-Z])/g, '$1 $2'));
        if (popupCurTagFilters?.length > 0){
            let count = 0;
            for (let i = 0; i < tagsToDisplay?.[val]?.length; i++) {
                if (popupCurTagFilters?.includes(tagsToDisplay?.[val]?.[i]?._id)) count++;
            }
            if (count > 0) str += ` (${count})`;
        }
        return str;
    }


    return (
        <React.Fragment>
            {tagsToDisplay &&
                <React.Fragment>
                    <ul className={"p-tabview-nav"}>
                        {Object?.keys(tagsToDisplay)?.map(key => (
                            <React.Fragment>
                                {tagsToDisplay?.[key]?.length > 0 &&
                                    <li style={{padding: '0px 2px'}}>
                                        <Dropdown
                                            options={tagsToDisplay?.[key]?.sort((a, b) => a._id.localeCompare(b._id))}
                                            placeholder={dropdownPlaceholder(key)}
                                            optionLabel={"_id"} itemTemplate={itemTemplate}
                                            panelClassName={"eventsDropdownPanel"}
                                            style={{marginRight: '5px'}}
                                            autoClose="inside"/>
                                    </li>
                                }
                            </React.Fragment>
                        ))}
                        <li style={{padding: '0px 2px', display:'flex', alignItems:'center'}}>
                            <button onClick={() => {
                                setPopupCurTagFilters([])
                                handleEventParams([]);
                            }}>Reset</button>
                        </li>
                    </ul>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
export default EventTagFilters