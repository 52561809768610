import React, {useContext, useEffect, useState} from 'react';
import fetchPois from "../../../../api/places/fetchPois";
import fetchDrivers from "../../../../api/drivers/fetchDrivers";
import NoDataMessage from "../grid/noDataMessage";
import {Dropdown} from "primereact/dropdown";
import {DataScroller} from "primereact/datascroller";
import indexContext from "../../../contexts/indexContext";


const Drivers = () => {

    const {winWidth, fleets} = useContext(indexContext)

    const [mounted, setMounted] = useState(false);
    const [fleet, setFleet] = useState();
    const [gridData, setGridData] = useState([]);


    const getDrivers = async () => {
        if (fleet){
            let r = await fetchDrivers({fleetId: fleet?.fleetId});
            setGridData(r);
            console.log(r)
        }
    }


    useEffect(async () => {
        if (mounted) await getDrivers();
    }, [fleet, mounted]);


    useEffect(() => {
        setMounted(true)

        return () => setMounted(false)
    }, [])


    return (
        <div>
            <div style={winWidth > 800 ? {width: '100%', height: '100%'} : {width: '100%', height: '100%'}}>

                {gridData?.length < 1 && !fleet &&
                    <div>
                        select a fleet
                        <Dropdown options={fleets} optionLabel={"fleetName"} value={fleet} placeholder="Select a fleet"
                                  onChange={(e) => setFleet(e.target.value)}/>
                    </div>
                }


            </div>
        </div>
    )
}

export default Drivers