import { getStoreData } from "./db";
import { getDrivingStatus } from "./drivingStatus";

export const filterVehicleList = async (queryObject) => {
    const store = await getStoreData('vehicles');
    const data2 = await filterFunc(store, queryObject);
    return data2
};

export const preFilterVehicleList = async (data, queryObject) => {
    const data2 = await filterFunc(data, queryObject);
    return data2
};

const filterFunc = async (curStoredVehicles, queryObject) => {
    const filteredVehicles = { type: "FeatureCollection", features: [] };

    filteredVehicles.features = await curStoredVehicles.filter(v => {
        // Check driving status
        const drivingStatus = getDrivingStatus(v?.properties?.lastHeartbeat,
            v?.properties?.lastLocation?.ac, new Date().getTime());

        if (!queryObject?.drivingStatus.has(drivingStatus)) {
            return false;
        }

        // Check fleets
        if (queryObject?.fleetsToShow?.size > 0 && !queryObject?.fleetsToShow.has(v.properties.fleetId)) {
            return false;
        }

        // Check chips
        if (queryObject?.chips.size > 0 && !matchChips(v, queryObject.chips)) {
            return false;
        }

        // Search query
        if (queryObject?.search?.length > 0 && !matchVehicle(v, queryObject)) {
            return false;
        }

        return true;
    });

    return filteredVehicles;
};

const matchChips = (v, chips) => {
    const lowerCaseChips = [...chips].map(m => m.toLowerCase());
    const tags = v?.properties?.tags || [];
    return lowerCaseChips.every(m =>
        tags.some(tag => tag.toLowerCase() === m) ||
        v?.properties?.registration?.toLowerCase()?.includes(m) ||
        v?.properties?.dn?.toLowerCase()?.includes(m)
    );
};

const matchVehicle = (v, queryObject) => {
    const lowerSearch = queryObject.search.toLowerCase();
    const lowerCaseChips = v?.properties?.tags?.map(m => m.toLowerCase());
    let chipMatch = false;

    for (let i = 0; i < lowerCaseChips?.length; i++) {
        if (lowerCaseChips[i].includes(lowerSearch)) chipMatch = true
    }

    // Match registration, driver, or dn
    if (v.properties?.registration?.toLowerCase().includes(lowerSearch) ||
        v.properties?.driver?.toLowerCase().includes(lowerSearch) ||
        v.properties?.dn?.toLowerCase().includes(lowerSearch) || chipMatch) {
        return true;
    }

    // Match address (parked)
    const drivingStatus = getDrivingStatus(v?.properties?.lastHeartbeat,
        v?.properties?.lastLocation?.ac, new Date().getTime());
    if (drivingStatus === 'parked') {
        return matchTownParked(v, lowerSearch) || matchPostcodeParked(v, lowerSearch);
    }

    return false;
};

const matchTownParked = (v, searchTerm) => {
    const address = v?.properties?.endAddress?.features?.[0]?.properties?.address;
    if (address?.town?.toLowerCase().includes(searchTerm)) return true;
    return address?.city?.toLowerCase().includes(searchTerm);
};

const matchPostcodeParked = (v, searchTerm) => {
    const postcode = v?.properties?.endAddress?.features?.[0]?.properties?.address?.postcode;
    return postcode?.toLowerCase().includes(searchTerm);
};
