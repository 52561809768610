import React, {useContext, useEffect, useRef, useState} from 'react';
import ReportsHeader from "../../abovePanelComponents/reports/reportsHeader";
import TimesheetReport from "./timesheetReport/timesheetReport";
import ActivityReport from "./activityReport/activityReport";
import mainContext from "../../../contexts/mainContext";
import reportsContext from "../../../contexts/reportsContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DriverBehaviourReport from "./driverBehaviour/driverBehaviourReport";
import CO2Report from "./co2Report/co2Report";
import IdlingReport from "./idlingReport/idlingReport";
import Co2ReportOverview from "./co2ReportOverview/co2ReportOverview";
import IdlingReportOverview from "./idlingReportOverview/idlingReportOverview";
import {Toast} from "primereact/toast";
import {faLeaf, faRoute, faGasPump, faCalendarClock, faGaugeMax, faSignsPost} from "@fortawesome/pro-thin-svg-icons";
import ReportsDatePicker from "./reportsDatePicker";
import {Dropdown} from "primereact/dropdown";
import indexContext from "../../../contexts/indexContext";
import fetchDeviceDetails from "../../../../api/device/fetchDeviceDetails";
import {TOAST_DURATION} from "../../../../Constants";
import SpeedingReportOverview from "./speedingOveriew/SpeedingReportOverview";
import PoiReport from "./poiReport/poiReport";
import {faLocation} from "@fortawesome/pro-regular-svg-icons";
import MovementReport from "./movementReport/movementReport";




const Reports = ({}) => {

    const {fleets} = useContext(indexContext)
    const {dateRange, reportType, handleDateRange, handleReportType, vehicle} = useContext(mainContext)


    const [reportLoading, setReportLoading] = useState(true);
    const [reportToPrint, setReportToPrint] = useState('');
    const [print, setPrint] = useState(false);
    const [showKpi, setShowKpi] = useState(true);
    const [theFleet, setTheFleet] = useState();
    const [theVehicle, setTheVehicle] = useState();
    const [theJourney, setTheJourney] = useState();
    const [theDeviceDetails, setTheDeviceDetails] = useState();
    const toast = useRef();


    const reportTypeOptions = [
        // {
        //     name: 'Out of hours',
        //     icon: faRoadLock,
        //     val: 'outOfHours'
        // },
        {
            display: 'Vehicle Activity',
            icon: faRoute,
            val: 'activity',
            description: 'Vehicle journeys and stops report',
            type: ['vehicle']
        },
        {
            display: 'Vehicle Timesheet',
            icon: faCalendarClock,
            val: 'timesheet',
            description: 'Weekly working time report',
            type: ['vehicle']
        },
        {
            display: 'Vehicle CO2',
            icon: faLeaf,
            val: 'co2',
            description: 'Detailed CO2 emmissions by journey',
            type: ['vehicle']
        },
        {
            display: 'Vehicle Idling',
            icon: faGasPump,
            val: 'idling',
            description: 'Detailed engine idling report by vehicle',
            type: ['vehicle']
        },
        // {
        //     display: 'Vehicle movement',
        //     icon: faLocation,
        //     val: 'movement',
        //     description: 'Detailed vehicle movement logging by vehicle',
        //     type: ['vehicle', 'journey']
        // },
        {
            display: 'Fleet CO2',
            icon: faLeaf,
            val: 'co2Overview',
            description: 'Fleet CO2 emmissions summary report (only vehicles that have emissions data).',
            type: ['fleet']
        },
        {
            display: 'Fleet Idling',
            icon: faGasPump,
            val: 'idlingOverview',
            description: 'Fleet engine idling summary report',
            type: ['fleet']
        },
        {
            display: 'Fleet Speeding',
            icon: faGaugeMax,
            val: 'speedingOverview',
            description: 'Fleet speeding report',
            type: ['fleet']
        },

        // {
        //     display: 'Fleet POI',
        //     icon: faSignsPost,
        //     val: 'poiOverview',
        //     description: 'Fleet POI report',
        //     type: ['fleet']
        // },

        // {
        //     display: 'Driver Behaviour',
        //     icon: faExclamationCircle,
        //     val: 'driverBehaviour',
        //     description: 'A report that summaries a driver\'s events over a given time period'
        // }
    ];

    const selectReport = (report) => {


        if (theVehicle && !theFleet && report?.type?.includes('fleet')){
            toast?.current?.show({ severity: 'warn', summary: 'Deselect Vehicle', detail: 'Please unselect the vehicle before requesting a fleet report',
                life: TOAST_DURATION });
            return;
        }

        if (theFleet && !theVehicle && report?.type?.includes('vehicle')){
            toast?.current?.show({ severity: 'warn', summary: 'Deselect Fleet', detail: 'Please unselect the fleet before requesting a vehicle report',
                life: TOAST_DURATION });
            return;
        }

        if (theFleet && theVehicle && report?.type?.includes('fleet')){
            toast?.current?.show({ severity: 'warn', summary: 'Deselect Vehicle', detail: 'Please unselect the vehicle before requesting a fleet report',
                life: TOAST_DURATION });
            return;
        }

        if (theFleet && theVehicle && report?.type?.includes('vehicle')){
            toast?.current?.show({ severity: 'warn', summary: 'Deselect Fleet', detail: 'Please unselect the fleet before requesting a vehicle report',
                life: TOAST_DURATION });
            return;
        }

        handleReportType(report)
    }


    useEffect(async () => {
        if (reportType){
            setReportToPrint(reportType.val);

        }
    }, [reportType])


    useEffect(() => {
        if(print){

            const date = new Date(dateRange[0])
            const start  = [
                ('0' + date.getDate()).slice(-2),
                ('0' + (date.getMonth() + 1)).slice(-2),
                date.getFullYear()
            ].join();

            const date2 = new Date(dateRange[0])
            const end  = [
                ('0' + date2.getDate()).slice(-2),
                ('0' + (date2.getMonth() + 1)).slice(-2),
                date2.getFullYear()
            ].join();


            let title = '';


            if (theVehicle){
              title += reportType.name + " report " + vehicle?.properties?.registration + " " + start + " - " + end + ".pdf";
            } else {

                title += reportType.name + " report " + theFleet + " " + start + " - " + end + ".pdf";
            }



            const tempTitle = document.title;
            document.title = title;

            window.print();
            setPrint(false);



            document.title = tempTitle;



        }
    }, [print]);

    const handleShowKpi = () => {
        setShowKpi(!showKpi);
    }


    const handlePrint = async () => {
        setPrint(true);
    }

    const handleReportLoading = (bool) => {

        setReportLoading(bool);
    }


    const handleTheJourney = val => setTheJourney(val);

    const handleTheFleet = (val) => {
        setTheFleet(val)
    }

    const handleTheVehicle = (val) => {
        setTheVehicle(val)
    }

    const handleTheDeviceDetails = (val) => {
        setTheDeviceDetails(val)
    }



    useEffect(() => {
        clearInterval(window.notificationsInterval);
    }, []);




    return (
        <React.Fragment>

            <reportsContext.Provider value={{dateRange, reportLoading, handleReportLoading, reportToPrint, showKpi,
                handleReportType, handleDateRange, reportType, handlePrint, handleShowKpi, reportTypeOptions, theFleet, handleTheFleet,
            theVehicle, handleTheVehicle, theDeviceDetails, handleTheDeviceDetails, handleTheJourney, theJourney}}>


                <ReportsHeader handleDateRange={handleDateRange} handleReportType={handleReportType} reportType={reportType}
                               dateRange={dateRange} handlePrint={handlePrint} handleShowKpi={handleShowKpi} showKpi={showKpi}
                               reportTypeOptions={reportTypeOptions} theVehicle={theVehicle} theFleet={theFleet}/>


                {!reportType &&
                    <React.Fragment>
                        <div style={{display: 'flex', flexWrap: 'wrap', padding: '20px 20px 20px 20px', gap: '20px',
                            background: 'var(--surface-ground)'}}>
                            {reportTypeOptions.map(report => (
                                <div className="p-card gridCard"
                                     style={{textAlign: 'center', padding: '5px', cursor: 'pointer', flex: '0 1 32%'}}
                                     onClick={() => selectReport(report)} key={report?.display}>
                                    <div className="p-card-header" style={{paddingTop: '13px'}}>
                                        <FontAwesomeIcon icon={report.icon} size="3x"/><br/>
                                        <div className="p-card-title" style={{marginTop: '8px'}}>
                                            {report?.display}
                                        </div>
                                    </div>
                                    <div className="p-card-body">
                                        {report?.description}
                                    </div>
                                </div>
                            ))}
                        </div>

                        {(theVehicle || theFleet) &&

                            <div style={{textAlign: 'center', background: 'var(--surface-ground)', fontSize: '18px'}}>
                                {theVehicle &&
                                    <div>
                                        Currently selected vehicle: {theVehicle?.properties?.registration}
                                        <br/><br/>
                                        <button className="p-button" style={{background: 'var(--v-list-button-selected)', color: 'var(--v-list-button-text-selected)'}}
                                                onClick={() => setTheVehicle(null)}>
                                            Deselect Vehicle
                                        </button>
                                    </div>
                                }
                                {theFleet &&
                                    <div>
                                        Currently selected fleet: {theFleet?.fleetName}
                                        <br/><br/>
                                        <button className="p-button" style={{background: 'var(--v-list-button-selected)', color: 'var(--v-list-button-text-selected)'}}
                                                onClick={() => setTheFleet(null)}>
                                            Deselect Fleet
                                        </button>
                                    </div>
                                }
                            </div>

                        }
                        {/*{(vehicle || fleetsToShow?.size > 0 || dateRange) &&*/}
                        {/*    <div style={{display: 'flex', justifyContent: 'center'}}>*/}


                        {/*        <div className="p-card"*/}
                        {/*             style={{textAlign: 'center', padding: '5px', cursor: 'pointer', flex: '0 1 32%'}}>*/}
                        {/*            <div className="p-card-header" style={{paddingTop: '13px'}}>*/}
                        {/*                <div className="p-card-title" style={{marginTop: '8px'}}>*/}
                        {/*                    Params*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <div className="p-card-body">*/}
                        {/*                <ReportsDatePicker handleDateRange={handleDateRange} dateRange={dateRange}/>*/}

                        {/*                {vehicle &&*/}
                        {/*                    <Dropdown options={vehicles?.features} optionLabel="properties.registration" filter filterBy="properties.registration"*/}
                        {/*                              style={{height: '46px', width: '202px', border: 'none', borderRadius: '0px', lineHeight: '35px', marginRight: '10px'}}*/}
                        {/*                              placeholder="Select vehicle"  value={vehicle}*/}
                        {/*                              onChange={(e) => handleVehicle(e.value)}*/}
                        {/*                    />*/}
                        {/*                }*/}

                        {/*                {fleetsToShow?.size > 0 &&*/}

                        {/*                    <Dropdown options={fleets} optionLabel="fleetName" filter filterBy="fleetName"*/}
                        {/*                              style={{height: '46px', width: '202px', border: 'none', borderRadius: '0px', lineHeight: '35px', marginRight: '10px'}}*/}
                        {/*                              placeholder="Select fleet"  value={theFleet}*/}
                        {/*                              onChange={(e) => {*/}
                        {/*                                  setTheFleet(e.value)*/}
                        {/*                                  handleFleetsToShow(new Set([e.value.fleetId]))*/}
                        {/*                              }}*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*            </div>*/}
                        {/*        </div>*/}



                        {/*    </div>*/}
                        {/*}*/}
                    </React.Fragment>
                }


                {reportType?.val === 'timesheet' &&
                    <TimesheetReport dateRange={dateRange} reportLoading={reportLoading}
                                     handleReportLoading={handleReportLoading}
                                     reportToPrint={reportToPrint} showKpi={showKpi}/>
                }

                {reportType?.val === 'activity' &&
                    <ActivityReport dateRange={dateRange} reportLoading={reportLoading}
                                    handleReportLoading={handleReportLoading}
                                    reportToPrint={reportToPrint} showKpi={showKpi}/>
                }

                {/*{reportType?.val === 'driverBehaviour' && dateRange &&*/}
                {/*    <DriverBehaviourReport dateRange={dateRange} />*/}
                {/*}*/}

                {reportType?.val === 'co2' &&
                    <CO2Report dateRange={dateRange} reportLoading={reportLoading}
                               handleReportLoading={handleReportLoading}
                               reportToPrint={reportToPrint} showKpi={showKpi} />
                }

                {reportType?.val === 'idling' &&
                    <IdlingReport dateRange={dateRange} reportLoading={reportLoading} handleReportLoading={handleReportLoading}
                                  reportToPrint={reportToPrint} showKpi={showKpi}/>
                }

                {reportType?.val === 'co2Overview' &&
                    <Co2ReportOverview dateRange={dateRange} reportLoading={reportLoading} handleReportLoading={handleReportLoading}
                                       reportToPrint={reportToPrint} showKpi={showKpi}/>
                }

                {reportType?.val === 'idlingOverview' &&
                    <IdlingReportOverview dateRange={dateRange} reportLoading={reportLoading} handleReportLoading={handleReportLoading}
                                          reportToPrint={reportToPrint} showKpi={showKpi}/>
                }

                {reportType?.val === 'speedingOverview' &&
                    <SpeedingReportOverview dateRange={dateRange} reportLoading={reportLoading} handleReportLoading={handleReportLoading}
                                            reportToPrint={reportToPrint} showKpi={showKpi} />
                }

                {reportType?.val === 'poiOverview' &&
                    <PoiReport dateRange={dateRange} reportLoading={reportLoading} handleReportLoading={handleReportLoading}
                                        reportToPrint={reportToPrint} showKpi={showKpi} />
                }

                {reportType?.val === 'movement' &&
                    <MovementReport dateRange={dateRange} reportLoading={reportLoading} handleReportLoading={handleReportLoading}
                                    reportToPrint={reportToPrint} showKpi={showKpi}/>
                }

            </reportsContext.Provider>





            <Toast ref={toast} appendTo={null} position="bottom-right"/>
        </React.Fragment>
    )
}
export default Reports
